/* eslint-disable no-console */
import { http } from "../plugin/http2-common";

class ProductService {
  listWithProducts() {
    return http.get("/category/list-with-producs");
  }

  saveAll(data) {
    
    console.log(data)
    return http.post("/product/bulk-process", data);
  }
}

export default new ProductService();
