<template>
  <b-popover :target="`popover-icon-${productType}-${currentRowIndex}`" triggers="hover" placement="lefttop">
    <template #title>
      <span style="color:blue">
        {{ currentName }}
      </span>
    </template>
    <div
      style="position: relative; text-align: center; width: fit-content; margin: auto;background-color: #B0B0B0;"
    >
      <img
        :src="currentImage"
        width="72px"
        height="72px"
      >
    </div>
  </b-popover>
</template>

<script>
export default {
  props: {
    productType: {
      type: String,
      default: ""
    },
    currentRowIndex: {
      type: Number,
      default: 0
    },
    currentName: {
      type: String,
      default: ""
    },
    currentImage: {
      type: String,
      default: ""
    }
  }
}
</script>