<template>
  <!-- <div style="overflow: auto; margin: 0 -6%; padding: 0 6%;" class="common-department"> -->
  <div style="margin: 0 -6%; padding: 0 6%" class="common-department">
    <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
      <div class="user-infor">
        <span>{{ countryAndLanguage }}</span>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light topnav">
        <div id="navbarText" :class="getTableHeaderClass + ' collapse navbar-collapse'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link
                exact
                class="nav-link pr-0 pl-0"
                to="/database/product-database"
                style=""
              >
                Product Database
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact
                class="nav-link pr-0 pl-0"
                to="/database/disclaimer"
              >
                Disclaimer Database
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <template v-if="!editable">
              <li class="nav-item">
                <b-button
                  type="button"
                  variant="outline-info custom-btn"
                  @click="turnOnEditable()"
                >
                  Edit content
                </b-button>
              </li>
            </template>
            <template v-else>
              <li class="nav-item">
                <b-button
                  variant="outline-info custom-btn btn-save"
                  @click="showModalSaveAll = true"
                >
                  Save
                </b-button>
                <b-button
                  variant="outline-info custom-btn"
                  @click="showModalCancelChange = true"
                >
                  Cancel
                </b-button>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>
    <div class="product-database">
      <div class="title-description">
        <span>All products added to the database will be displayed in the
          Application as a dropdown option.</span>
      </div>
      <div style="margin-bottom: 22px">
        <b-form-group label="The E-Life products in this database are:">
          <!-- <b-form-radio-group
            id="radio-group-1"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
            @change="changeProductTypeRadio($event)"
          /> -->
          <div v-if="!editable" class="radio-item">
            <input
              id="ritema"
              type="radio"
              name="radio-options"
              value="E_LIFE_PRO"
              :checked="selected == 'E_LIFE_PRO'"
              disabled
            />
            <label for="ritema">E-Life Pro</label>
          </div>
          <div v-if="!editable" class="radio-item">
            <input
              id="ritemb"
              type="radio"
              name="radio-options"
              value="E_LIFE"
              :checked="selected == 'E_LIFE'"
              disabled
            />
            <label for="ritemb">E-Life Neo</label>
          </div>
          <div v-if="editable" class="radio-item">
            <input
              id="ritema"
              type="radio"
              name="radio-options"
              value="E_LIFE_PRO"
              :checked="selected == 'E_LIFE_PRO'"
              @change="changeProductTypeRadio('E_LIFE_PRO')"
            />
            <label for="ritema">E-Life Pro</label>
          </div>
          <div v-if="editable" class="radio-item">
            <input
              id="ritemb"
              type="radio"
              name="radio-options"
              value="E_LIFE"
              :checked="selected == 'E_LIFE'"
              @change="changeProductTypeRadio('E_LIFE')"
            />
            <label for="ritemb">E-Life Neo</label>
          </div>
        </b-form-group>
      </div>
      <div style="margin: 0; padding: 0" class="table-responsive">
        <table
          id="table-1"
          class="table-sm table custom-table"
          responsive
          sm
          borderless
        >
          <thead>
            <tr>
              <th style="width: 8%" scope="col" :class="getTableHeaderClass">
                <div class="align-middle">
                  <strong> CATEGORY </strong>
                  <img
                    id="tooltip-category"
                    class="tooltip-img"
                    style="width: 20px; height: 20px"
                    src="@/assets/icons/tooltip.png"
                  />
                  <b-tooltip style="margin-top: -5px"
                             class="btn-tooltip" target="tooltip-category" triggers="hover" placement="bottom" custom-class="custom-tooltip"
                  >
                    <div class="text-left">
                      jpeg. or png files only. Max 1MB. Dimension 1164 x 440px
                    </div>
                  </b-tooltip>
                </div>
              </th>
              <th style="width: 8%" scope="col" :class="getTableHeaderClass">
                <strong> SUB-CATEGORY </strong>
              </th>
              <th style="width: 12%" scope="col" :class="getTableHeaderClass">
                <strong> PRODUCT NAME </strong>
              </th>
              <th style="width: 11%" scope="col" :class="getTableHeaderClass">
                <strong> CONSUMER NAME </strong>
              </th>
              <th style="width: 16%" scope="col" :class="getTableHeaderClass">
                <strong> DESCRIPTION </strong>
              </th>
              <th style="width: 11%" scope="col" :class="getTableHeaderClass">
                <div class="align-middle">
                  <strong> WARNING TEXT </strong>
                  <img
                    id="tooltip-warning-text"
                    class="tooltip-img"
                    style="width: 20px; height: 20px"
                    src="@/assets/icons/tooltip.png"
                  />
                  <b-tooltip style="margin-top: -5px"
                             class="btn-tooltip" target="tooltip-warning-text" triggers="hover" placement="bottom" custom-class="custom-tooltip"
                  >
                    <div class="text-left">
                      You can add a disclaimer when you recommend a lens design to give more insights to ECP (eg. the disclaimer for e-life product: This lens is not recommend for driving). This disclaimer will be displayed in the summary screen below the product recommendation.
                    </div>
                  </b-tooltip>
                </div>
              </th>
              <th style="width: 11%" scope="col" :class="getTableHeaderClass">
                <strong class="text-uppercase"> Product Website Page </strong>
                <img
                  id="tooltip-product-website"
                  class="tooltip-img"
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
                <b-tooltip style="margin-top: -5px"
                           class="btn-tooltip" target="tooltip-product-website" triggers="hover" placement="bottom" custom-class="custom-tooltip"
                >
                  <div class="text-left">
                    The URL link enables you to provide more information to consumers about the category and invite them to visit your local website to learn more. The recommendations for consumers are always categories of solutions but never direct products names therefore please make sure the URL does not link to a specific products but to a category of multiple solutions such as blue solutions, Progressive lenses, etc.
                  </div>
                </b-tooltip>
              </th>
              <th style="width: 10%" scope="col" :class="getTableHeaderClass">
                <strong class="text-uppercase"> Product Packages Password </strong>
                <img
                  id="tooltip-product-package"
                  class="tooltip-img"
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
                <b-tooltip style="margin-top: -5px"
                           class="btn-tooltip" target="tooltip-product-package" triggers="hover" placement="bottom" custom-class="custom-tooltip"
                >
                  <div class="text-left">
                    The ECP link enables you to make a link to a specific package of the Nikon Difference. As you can see in your Nikon Difference app CMS, each package has a specific password. By entering this password within the Lens Selector CMS, you can create a link to a package of product presentations. Please note that link to specific documents are not possible but you can only link to a package. It is important to make sure your ECPs have downloaded all the packages otherwise the links will be broken. You can also decide to create a link to all the packages or to have no link at all.
                  </div>
                </b-tooltip>
              </th>
              <th style="width: 6%" scope="col" :class="getTableHeaderClass">
                <strong> ICON </strong>
                <img
                  id="tooltip-icon"
                  class="tooltip-img"
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
                <b-tooltip style="margin-top: -5px"
                           class="btn-tooltip" target="tooltip-icon" triggers="hover" placement="bottom" custom-class="custom-tooltip"
                >
                  <div class="text-left">
                    You are free to select icons for each lens design, to customize the recommendation portion of the survey results. (png files only. Max 20KB. Dimension 144 x 144px)
                  </div>
                </b-tooltip>
              </th>
              <th style="width: 7%" scope="col" :class="getTableHeaderClass">
                <strong> IMAGE </strong>
                <img
                  id="tooltip-image"
                  class="tooltip-img"
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
                <b-tooltip style="margin-top: -5px"
                           class="btn-tooltip" target="tooltip-image" triggers="hover" placement="bottom" custom-class="custom-tooltip"
                >
                  <div class="text-left">
                    You can select images to illustrate each lens design, coating and tint. We recommend using wearer visuals for a consumer friendly and relatable experience. The lens design pictures will be shown in the recommendation portion of the survey results, and the coating and tint pictures will be shown in the countermat portion of the survey results. (jpeg. or png files only. Max 1MB. Dimension 1164 x 440px)
                  </div>
                </b-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <!---####################### VISION CATEGORY ##########################################################---->
            <!-- list VisionCategory -->
            <template v-if="singleVisionCategory.products.length == 0">
              <tr>
                <td :rowspan="1 + editable" class="s">
                  <CustomEditor 
                    v-model="singleVisionCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    v-show="singleVisionCategory.image"
                    :id="`popover-category-${singleVisionActionType}-99`"
                    class="category-img"
                    :src="IMAGE_API_PATH + singleVisionCategory.image"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = singleVisionActionType;
                    "
                  />
                  <CategoryImagePopover
                    :product-type="singleVisionActionType"
                    :current-row-index="99"
                    :current-name="singleVisionCategory.name"
                    :current-image="IMAGE_API_PATH + singleVisionCategory.image"
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    v-show="editable"
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = singleVisionActionType;
                    "
                  >
                    <template v-if="singleVisionCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" />
                </td>
              </tr>
            </template>
            <!-- liststart -->
            <template v-if="!editable">
              <tr
                v-for="(product, index) in singleVisionCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="singleVisionCategory.products.length"
                  class="s"
                >
                  <CustomEditor 
                    v-model="singleVisionCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    :id="`popover-category-${singleVisionActionType}-${index}`"
                    class="category-img"
                    :src="IMAGE_API_PATH + singleVisionCategory.image"
                  />
                  <CategoryImagePopover
                    :product-type="singleVisionActionType"
                    :current-row-index="index"
                    :current-name="singleVisionCategory.name"
                    :current-image="IMAGE_API_PATH + singleVisionCategory.image"
                  />
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.name" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.consumerName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.description" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.warningText" class-name="form-control" />
                </td>
                <td style="max-width: 250px">
                  <a
                    style="word-break: break-word"
                    :href="product.consumerUrl"
                    class="link-primary"
                    target="_blank"
                    rel="noreferrer noopener"
                  >{{ product.consumerUrl }}</a>
                </td>
                <td v-show="product.ecpLink == 'Enter password'">
                  {{ product.ecpPassword }}
                </td>
                <td v-show="product.ecpLink != 'Enter password'">
                  {{ product.ecpLink }}
                </td>
                <template v-if="product.icon">
                  <td>
                    <div
                      :id="`popover-icon-${singleVisionActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.icon" />
                    </div>
                    <IconPopover
                      :product-type="singleVisionActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.icon"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
                <template v-if="product.image">
                  <td>
                    <div
                      :id="`popover-image-${singleVisionActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.image" />
                    </div>
                    <ImagePopover
                      :product-type="singleVisionActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.image"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
              </tr>
            </template>
            <!-- end list VisionCategory-->

            <!-- editable VisionCategory-->
            <template v-else>
              <tr
                v-for="(product, index) in singleVisionCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="singleVisionCategory.products.length + 1"
                  class="s"
                >
                  <CustomEditor 
                    v-model="singleVisionCategory.name"
                    type="textarea"
                    class-name="form-control category-name"
                    :rows="3"
                    :maxlength="26"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>

                  <img
                    v-show="singleVisionCategory.image"
                    class="category-img"
                    :src="IMAGE_API_PATH + singleVisionCategory.image"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = singleVisionActionType;
                    "
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = singleVisionActionType;
                    "
                  >
                    <template v-if="singleVisionCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" />
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.name"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="35"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.consumerName"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="80"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.description"
                    type="textarea"
                    class-name="form-control"
                    :rows="5"
                    :maxlength="145"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.warningText"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="50"
                  />
                </td>
                <td>
                  <textarea-autosize
                    v-model="product.consumerUrl"
                    type="url"
                    class="form-control"
                  />
                </td>
                <td>
                  <select v-model="product.ecpLink" class="form-control">
                    <option
                      v-for="ecpLink in ecpLinks"
                      :key="ecpLink"
                      :value="ecpLink"
                    >
                      {{ ecpLink }}
                    </option>
                  </select>
                  <b-row
                    v-show="product.ecpLink == 'Enter password'"
                    style="margin: 5px 0 0 0; padding: 0"
                  >
                    <b-col cols="3" style="margin: auto 0; padding: 0">
                      Pw:
                    </b-col>
                    <b-col cols="9" style="margin: 0; padding: 0">
                      <input
                        v-model="product.ecpPassword"
                        style="padding: 2px"
                        type="text"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.icon"
                      :src="IMAGE_API_PATH + product.icon"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = singleVisionActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = singleVisionActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInput"
                      type="file"
                      @change="chooseIconWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        iconOnClick(index);
                        changeImageActionType = singleVisionActionType;
                      "
                    >
                      <template v-if="product.id || product.icon">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.icon"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                  </div>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.image"
                      :src="IMAGE_API_PATH + product.image"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = singleVisionActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = singleVisionActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInputImage"
                      type="file"
                      @change="chooseImageWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        imageOnClick(index);
                        changeImageActionType = singleVisionActionType;
                      "
                    >
                      <template v-if="product.id || product.image">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.image"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                    <b-icon
                      v-show="!product.canNotBeDeleted"
                      class="pointer"
                      icon="x-circle"
                      variant="danger"
                      @click="
                        removeProduct(index, product),
                        (removeActionType = singleVisionActionType)
                      "
                    />
                  </div>
                </td>
              </tr>
            </template>

            <!-- end editable VisionCategory-->

            <!-- add button VisionCategory-->
            <tr v-show="editable" class="table-borderless">
              <td />
              <td>
                <b-button
                  variant="btn btn-info"
                  style="
                    margin-bottom: 10px;
                    width: 100%;
                    background: #006db6;
                    color: white;
                  "
                  @click="addSingleVisionProduct()"
                >
                  + add more products
                </b-button>
              </td>
              <!-- <td>
              <b-button
                variant="btn btn-info"
                style="  margin-bottom: 10px;"
                @click="addRelaxseeNeoProduct()"
              >
                + add Relaxsee Neo
              </b-button>
            </td> -->
            </tr>
            <!-- end add button VisionCategory-->

            <!--######################### VISION CATEGORY END ######################################################--->

            <!--######################### PROGRESSIVE CATEGORY --####################################################--->

            <!-- list progressiveLensCategory -->
            <template v-if="progressiveLensCategory.products.length == 0">
              <tr class="bold-border">
                <td :rowspan="1 + editable" class="s">
                  <CustomEditor 
                    v-model="progressiveLensCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    v-show="progressiveLensCategory.image"
                    :id="`popover-category-${progressiveActionType}-99`"
                    class="category-img"
                    :src="IMAGE_API_PATH + progressiveLensCategory.image"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = progressiveActionType;
                    "
                  />
                  <CategoryImagePopover
                    :product-type="progressiveActionType"
                    :current-row-index="99"
                    :current-name="progressiveLensCategory.name"
                    :current-image="
                      IMAGE_API_PATH + progressiveLensCategory.image
                    "
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    v-show="editable"
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = progressiveActionType;
                    "
                  >
                    <template v-if="progressiveLensCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" />
                </td>
              </tr>
            </template>
            <!-- liststart -->
            <template v-if="!editable">
              <tr
                v-for="(product, index) in progressiveLensCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="progressiveLensCategory.products.length"
                  class="s"
                >
                  <CustomEditor 
                    v-model="progressiveLensCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    :id="`popover-category-${progressiveActionType}-${index}`"
                    class="category-img"
                    :src="IMAGE_API_PATH + progressiveLensCategory.image"
                  />
                  <CategoryImagePopover
                    :product-type="progressiveActionType"
                    :current-row-index="index"
                    :current-name="progressiveLensCategory.name"
                    :current-image="
                      IMAGE_API_PATH + progressiveLensCategory.image
                    "
                  />
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.name" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.consumerName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.description" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.warningText" class-name="form-control" />
                </td>
                <td style="max-width: 250px">
                  <a
                    style="word-wrap: break-word"
                    :href="product.consumerUrl"
                    class="link-primary"
                    target="_blank"
                    rel="noreferrer noopener"
                  >{{ product.consumerUrl }}</a>
                </td>
                <td v-show="product.ecpLink == 'Enter password'">
                  {{ product.ecpPassword }}
                </td>
                <td v-show="product.ecpLink != 'Enter password'">
                  {{ product.ecpLink }}
                </td>
                <template v-if="product.icon">
                  <td>
                    <div
                      :id="`popover-icon-${progressiveActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.icon" />
                    </div>
                    <IconPopover
                      :product-type="progressiveActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.icon"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
                <template v-if="product.image">
                  <td>
                    <div
                      :id="`popover-image-${progressiveActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.image" />
                    </div>
                    <ImagePopover
                      :product-type="progressiveActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.image"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
              </tr>
            </template>
            <!-- end list progressiveLensCategory-->

            <!-- editable progressiveLensCategory-->
            <template v-else>
              <tr
                v-for="(product, index) in progressiveLensCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="progressiveLensCategory.products.length + 1"
                  class="s"
                >
                  <CustomEditor 
                    v-model="progressiveLensCategory.name"
                    type="textarea"
                    class-name="form-control category-name"
                    :rows="3"
                    :maxlength="26"
                  />
                  <span>Image:</span>
                  <img
                    v-show="progressiveLensCategory.image"
                    class="category-img"
                    :src="IMAGE_API_PATH + progressiveLensCategory.image"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = progressiveActionType;
                    "
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = progressiveActionType;
                    "
                  >
                    <template v-if="progressiveLensCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" />
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.name"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="35"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.consumerName"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="80"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.description"
                    type="textarea"
                    class-name="form-control"
                    :rows="5"
                    :maxlength="145"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.warningText"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="50"
                  />
                </td>
                <td>
                  <textarea-autosize
                    v-model="product.consumerUrl"
                    type="url"
                    class="form-control"
                  />
                </td>
                <td>
                  <select v-model="product.ecpLink" class="form-control">
                    <option
                      v-for="ecpLink in ecpLinks"
                      :key="ecpLink"
                      :value="ecpLink"
                    >
                      {{ ecpLink }}
                    </option>
                  </select>
                  <b-row
                    v-show="product.ecpLink == 'Enter password'"
                    style="margin: 5px 0 0 0; padding: 0"
                  >
                    <b-col cols="3" style="margin: auto 0; padding: 0">
                      Pw:
                    </b-col>
                    <b-col cols="9" style="margin: 0; padding: 0">
                      <input
                        v-model="product.ecpPassword"
                        style="padding: 2px"
                        type="text"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.icon"
                      :src="IMAGE_API_PATH + product.icon"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = progressiveActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = progressiveActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInput"
                      type="file"
                      @change="chooseIconWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        iconOnClick(index);
                        changeImageActionType = progressiveActionType;
                      "
                    >
                      <template v-if="product.id || product.icon">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.icon"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                  </div>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.image"
                      :src="IMAGE_API_PATH + product.image"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = progressiveActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = progressiveActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInputImage"
                      type="file"
                      @change="chooseImageWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        imageOnClick(index);
                        changeImageActionType = progressiveActionType;
                      "
                    >
                      <template v-if="product.id || product.image">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.image"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                    <b-icon
                      v-show="!product.canNotBeDeleted"
                      class="pointer"
                      icon="x-circle"
                      variant="danger"
                      @click="
                        removeProduct(index, product);
                        removeActionType = progressiveActionType;
                      "
                    />
                  </div>
                </td>
              </tr>
            </template>

            <!-- end editable progressiveLensCategory-->

            <!-- add button progressiveLensCategory-->
            <tr v-show="editable" class="table-borderless">
              <td />
              <td>
                <b-button
                  variant="btn btn-info"
                  style="
                    margin-bottom: 10px;
                    width: 100%;
                    background: #006db6;
                    color: white;
                  "
                  @click="addProgressiveLensProduct()"
                >
                  + add more products
                </b-button>
              </td>
              <!-- <td>
              <b-button
                variant="btn btn-info"
                style="  margin-bottom: 10px;"
                @click="addRelaxseeNeoProgressiveProduct()"
              >
                + add Relaxsee Neo
              </b-button>
            </td> -->
            </tr>
            <!-- end add button progressiveLensCategory-->
            <!--########################- PROGRESSIVE CATEGORY END ####################################################--->

            <!--######################### VOILA CATEGORY ##############################################################--->
            <!-- list voilaCategory -->
            <template v-if="selected == 'E_LIFE_PRO'">
              <template v-if="voilaCategory.products.length == 0">
                <tr class="bold-border">
                  <td :rowspan="1 + editable" class="s">
                    <template v-if="!editable">
                      <CustomEditor 
                        v-model="voilaCategory.name"
                        main-class-name="category-name"
                        class-name="form-control"
                      />
                      <br />
                    </template>
                    <template v-else>
                      <CustomEditor 
                        v-model="voilaCategory.name"
                        type="textarea"
                        class-name="form-control category-name"
                        :rows="3"
                        :maxlength="26"
                      />
                    </template>
                  </td>
                </tr>
              </template>
              <!-- liststart -->
              <template v-if="!editable">
                <tr v-for="(product, index) in voilaCategory.products" :key="product.id" :class="{ 'bold-border': index === 0 }">
                  <td v-if="index === 0" :rowspan="voilaCategory.products.length" class="s">
                    <CustomEditor 
                      v-model="voilaCategory.name"
                      main-class-name="category-name"
                      class-name="form-control"
                    />
                    <br />
                  </td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.name" class-name="form-control" />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="true" />
                      <span class="checkmark" /> Disable this lens
                    </label>
                  </td>
                  <td>
                    <CustomEditor v-model="product.consumerName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.description" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.warningText" class-name="form-control" />
                  </td>
                  <td style="max-width: 250px">
                    <a
                      style="word-wrap: break-word"
                      :href="product.consumerUrl"
                      class="link-primary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >{{ product.consumerUrl }}</a>
                  </td>
                  <td v-show="product.ecpLink == 'Enter password'">
                    {{ product.ecpPassword }}
                  </td>
                  <td v-show="product.ecpLink != 'Enter password'">
                    {{ product.ecpLink }}
                  </td>
                  <template v-if="product.icon">
                    <td>
                      <div
                        :id="`popover-icon-${voilaActionType}-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.icon" />
                      </div>
                      <IconPopover
                        :product-type="voilaActionType"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.icon"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                  <template v-if="product.image">
                    <td>
                      <div
                        :id="`popover-image-${voilaActionType}-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.image" />
                      </div>
                      <ImagePopover
                        :product-type="voilaActionType"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.image"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                </tr>
                <!-- list product level 2 -->
                <tr v-for="(product, index) in voilaCategory.productsLevel2" :key="product.id">
                  <td></td>
                  <td v-if="voilaCategory.productsLevel3.length > 0" colspan="4" style="text-align: left; font-size: 17px; font-weight: bold;">
                    {{ product.name }}
                    <b-button
                      v-b-tooltip.hover
                      style="margin-top: -5px"
                      title="If your markets do not have these 4 types of Read product, and do not want to use the Soltes calculator, delete Read and add as a standard E-Life product."
                      class="btn-tooltip"
                    >
                      <img
                        style="width: 20px; height: 20px"
                        src="@/assets/icons/tooltip.png"
                      />
                    </b-button>
                  </td>
                  <td></td>
                  <td>
                    <CustomEditor v-model="product.consumerName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.description" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.warningText" class-name="form-control" />
                  </td>
                  <td style="max-width: 250px">
                    <a
                      style="word-wrap: break-word"
                      :href="product.consumerUrl"
                      class="link-primary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >{{ product.consumerUrl }}</a>
                  </td>
                  <template v-if="product.icon">
                    <td>
                      <div
                        :id="`popover-icon-${voilaActionType}-productsLevel2-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.icon" />
                      </div>
                      <IconPopover
                        :product-type="`${voilaActionType}-productsLevel2`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.icon"
                      />
                    </td>
                  </template>
                  <template v-if="product.image">
                    <td>
                      <div
                        :id="`popover-image-${voilaActionType}-productsLevel2-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.image" />
                      </div>
                      <ImagePopover
                        :product-type="`${voilaActionType}-productsLevel2`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.image"
                      />
                    </td>
                  </template>
                </tr>
                <tr v-for="(product, index) in voilaCategory.productsLevel3" :key="product.id">
                  <td></td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="true" />
                      <span class="checkmark" /> Disable this lens type
                    </label>
                  </td>
                  <td>
                    <CustomEditor v-model="product.name" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.consumerName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.description" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.warningText" class-name="form-control" />
                  </td>
                  <td style="max-width: 250px">
                    <a
                      style="word-wrap: break-word"
                      :href="product.consumerUrl"
                      class="link-primary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >{{ product.consumerUrl }}</a>
                  </td>
                  <td v-show="product.ecpLink == 'Enter password'">
                    {{ product.ecpPassword }}
                  </td>
                  <td v-show="product.ecpLink != 'Enter password'">
                    {{ product.ecpLink }}
                  </td>
                  <template v-if="product.icon">
                    <td>
                      <div
                        :id="`popover-icon-${voilaActionType}-productsLevel3-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.icon" />
                      </div>
                      <IconPopover
                        :product-type="`${voilaActionType}-productsLevel3`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.icon"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                  <template v-if="product.image">
                    <td>
                      <div
                        :id="`popover-image-${voilaActionType}-productsLevel3-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.image" />
                      </div>
                      <ImagePopover
                        :product-type="`${voilaActionType}-productsLevel3`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.image"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                </tr>
              </template>
              <!-- end list voilaCategory-->

              <!-- editable voilaCategory-->
              <template v-else>
                <tr v-for="(product, index) in voilaCategory.products" :key="product.id" :class="{ 'bold-border': index === 0 }">
                  <td v-if="index === 0" :rowspan="voilaCategory.products.length + 1" class="s">
                    <CustomEditor 
                      v-model="voilaCategory.name"
                      type="textarea"
                      class-name="form-control category-name"
                      :rows="3"
                      :maxlength="26"
                    />
                  </td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.name"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="35"
                    />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="product.canNotBeDeleted && !product.isDisabled" />
                      <span class="checkmark" /> Disable this lens
                    </label>
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.consumerName"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="80"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.description"
                      type="textarea"
                      class-name="form-control"
                      :rows="5"
                      :maxlength="145"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.warningText"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="50"
                    />
                  </td>
                  <td>
                  </td>
                  <td>
                    <select v-model="product.ecpLink" class="form-control">
                      <option
                        v-for="ecpLink in ecpLinks"
                        :key="ecpLink"
                        :value="ecpLink"
                      >
                        {{ ecpLink }}
                      </option>
                    </select>
                    <b-row
                      v-show="product.ecpLink == 'Enter password'"
                      style="margin: 5px 0 0 0; padding: 0"
                    >
                      <b-col cols="3" style="margin: auto 0; padding: 0">
                        Pw:
                      </b-col>
                      <b-col cols="9" style="margin: 0; padding: 0">
                        <input
                          v-model="product.ecpPassword"
                          style="padding: 2px"
                          type="text"
                          class="form-control"
                        />
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.icon"
                        :src="IMAGE_API_PATH + product.icon"
                        @click="
                          iconOnClick(index);
                          changeImageActionType = voilaActionType;
                        "
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="
                          iconOnClick(index);
                          changeImageActionType = voilaActionType;
                        "
                      />
                      <input
                        v-show="false"
                        ref="fileInput"
                        type="file"
                        @change="chooseIconWithWireframe($event,1)"
                      />
                      <button
                        id="falseinput"
                        style="
                          background-color: white;
                          border: none;
                          height: 40px;
                        "
                        @click="
                          iconOnClick(index);
                          changeImageActionType = voilaActionType;
                        "
                      >
                        <template v-if="product.id || product.icon">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.icon"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                    </div>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.image"
                        :src="IMAGE_API_PATH + product.image"
                        @click="
                          imageOnClick(index);
                          changeImageActionType = voilaActionType;
                        "
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="
                          imageOnClick(index);
                          changeImageActionType = voilaActionType;
                        "
                      />
                      <input
                        v-show="false"
                        ref="fileInputImage"
                        type="file"
                        @change="chooseImageWithWireframe($event,1)"
                      />
                      <button
                        id="falseinput"
                        style="
                          background-color: white;
                          border: none;
                          height: 40px;
                        "
                        @click="
                          imageOnClick(index);
                          changeImageActionType = voilaActionType;
                        "
                      >
                        <template v-if="product.id || product.image">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.image"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                      <b-icon
                        v-show="!product.canNotBeDeleted && product.productType === null"
                        class="pointer"
                        icon="x-circle"
                        variant="danger"
                        @click="
                          removeProduct(index, product);
                          removeActionType = voilaActionType;
                        "
                      />
                    </div>
                  </td>
                </tr>
                <!-- product level 2 -->
                <tr v-for="(product) in voilaCategory.productsLevel2" :key="product.id">
                  <td v-if="voilaCategory.productsLevel3.length > 0" style="text-align: left; font-size: 17px; font-weight: bold;">
                    <CustomEditor 
                      v-model="product.name"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="35"
                    />
                  </td>
                  <td v-if="voilaCategory.productsLevel3.length > 0" colspan="4" style="text-align: left; font-size: 17px; font-weight: bold;">
                    <b-button
                      v-b-tooltip.hover
                      style="margin-top: -5px"
                      title="If your markets do not have these 4 types of Read product, and do not want to use the Soltes calculator, delete Read and add as a standard E-Life product."
                      class="btn-tooltip"
                    >
                      <img
                        style="width: 20px; height: 20px"
                        src="@/assets/icons/tooltip.png"
                      />
                    </b-button>
                  </td>
                  <td>
                    <div class="editor"></div>
                  </td>
                  <td>
                    <div class="editor"></div>
                  </td>
                  <td>
                    <div class="editor"></div>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr v-for="(product, index) in voilaCategory.productsLevel3" :key="product.id">
                  <td class="s"></td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="isLensTypeDisabled(index, false)" />
                      <span class="checkmark" /> Disable this lens type
                    </label>
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.name"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="35"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.consumerName"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="80"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.description"
                      type="textarea"
                      class-name="form-control"
                      :rows="5"
                      :maxlength="145"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.warningText"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="50"
                    />
                  </td>
                  <td>
                  </td>
                  <td>
                    <select v-model="product.ecpLink" class="form-control">
                      <option
                        v-for="ecpLink in ecpLinks"
                        :key="ecpLink"
                        :value="ecpLink"
                      >
                        {{ ecpLink }}
                      </option>
                    </select>
                    <b-row
                      v-show="product.ecpLink == 'Enter password'"
                      style="margin: 5px 0 0 0; padding: 0"
                    >
                      <b-col cols="3" style="margin: auto 0; padding: 0">
                        Pw:
                      </b-col>
                      <b-col cols="9" style="margin: 0; padding: 0">
                        <input
                          v-model="product.ecpPassword"
                          style="padding: 2px"
                          type="text"
                          class="form-control"
                        />
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.icon"
                        :src="IMAGE_API_PATH + product.icon"
                        @click="iconOnClickLevel3(index); changeImageActionType = voilaActionType;"
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="iconOnClickLevel3(index); changeImageActionType = voilaActionType;"
                      />
                      <input
                        v-show="false"
                        ref="fileInputLevel3"
                        type="file"
                        @change="chooseIconWithWireframe($event,3)"
                      />
                      <button
                        id="falseinput"
                        style="
                            background-color: white;
                            border: none;
                            height: 40px;
                          "
                        @click="iconOnClickLevel3(index); changeImageActionType = voilaActionType;"
                      >
                        <template v-if="product.id || product.icon">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.icon"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                    </div>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.image"
                        :src="IMAGE_API_PATH + product.image"
                        @click="imageOnClickLevel3(index); changeImageActionType = voilaActionType;"
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="imageOnClickLevel3(index); changeImageActionType = voilaActionType;"
                      />
                      <input
                        v-show="false"
                        ref="fileInputImageLevel3"
                        type="file"
                        @change="chooseImageWithWireframe($event,3)"
                      />
                      <button
                        id="falseinput"
                        style="
                            background-color: white;
                            border: none;
                            height: 40px;
                          "
                        @click="imageOnClickLevel3(index); changeImageActionType = voilaActionType;"
                      >
                        <template v-if="product.id || product.image">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.image"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                      <b-icon
                        v-show="false && !product.canNotBeDeleted"
                        class="pointer"
                        icon="x-circle"
                        variant="danger"
                        @click="removeProduct(index, product); removeActionType = voilaActionType;"
                      />
                    </div>
                  </td>
                </tr>
              </template>
              <!-- end editable voilaCategory-->

              <!-- add button voilaCategory-->
              <tr v-show="editable" class="table-borderless">
                <td />
                <td>
                  <b-button
                    variant="btn btn-info"
                    style="
                      margin-bottom: 10px;
                      width: 100%;
                      background: #006db6;
                      color: white;
                    "
                    @click="addVoilaProduct()"
                  >
                    + add more products
                  </b-button>
                </td>
                <!-- <td>
                  <b-button
                    variant="btn btn-info"
                    style="  margin-bottom: 10px; background: #006DB6; color: white"
                    @click="addELifeProActiveProducts()"
                  >
                    + add E-Life Pro Active
                  </b-button>
                </td> -->
                <td v-if="!hasSubAddElifeProReadProduct">
                  <b-button
                    variant="btn btn-info"
                    style="
                      margin-bottom: 10px;
                      background: #006db6;
                      color: white;
                    "
                    @click="addELifeProReadProducts()"
                  >
                    + add E-Life Pro Read
                  </b-button>
                </td>
              </tr>
            </template>
            <!-- end add button voilaCategory -->
            <template v-if="selected == 'E_LIFE'">
              <template v-if="seriesCategory.products.length == 0">
                <tr class="bold-border">
                  <td :rowspan="1 + editable" class="s">
                    <CustomEditor 
                      v-model="seriesCategory.name"
                      main-class-name="category-name"
                      class-name="form-control"
                    />
                    <br />
                  </td>
                </tr>
              </template>
              <template v-if="!editable">
                <tr
                  v-for="(product, index) in seriesCategory.products"
                  :key="product.id"
                  :class="{ 'bold-border': index === 0 }"
                >
                  <td
                    v-if="index === 0"
                    :rowspan="seriesCategory.products.length"
                    class="s"
                  >
                    <CustomEditor 
                      v-model="seriesCategory.name"
                      main-class-name="category-name"
                      class-name="form-control"
                    />
                    <br />
                  </td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.name" class-name="form-control" />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="true" />
                      <span class="checkmark" /> Disable this lens
                    </label>
                  </td>
                  <td>
                    <CustomEditor v-model="product.consumerName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.description" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.warningText" class-name="form-control" />
                  </td>
                  <td style="max-width: 250px">
                    <a
                      style="word-wrap: break-word"
                      :href="product.consumerUrl"
                      class="link-primary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >{{ product.consumerUrl }}</a>
                  </td>
                  <td v-show="product.ecpLink == 'Enter password'">
                    {{ product.ecpPassword }}
                  </td>
                  <td v-show="product.ecpLink != 'Enter password'">
                    {{ product.ecpLink }}
                  </td>
                  <template v-if="product.icon">
                    <td>
                      <div
                        :id="`popover-icon-${seriesActionType}-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.icon" />
                      </div>
                      <IconPopover
                        :product-type="seriesActionType"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.icon"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                  <template v-if="product.image">
                    <td>
                      <div
                        :id="`popover-image-${seriesActionType}-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.image" />
                      </div>
                      <ImagePopover
                        :product-type="seriesActionType"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.image"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                </tr>
                <!-- elife level 2 -->
                <tr v-for="(product, index) in seriesCategory.productsLevel2" :key="product.id">
                  <td></td>
                  <td v-if="seriesCategory.productsLevel3.length > 0" colspan="4" style="text-align: left; font-size: 17px; font-weight: bold;">
                    {{ product.name }}
                    <b-button
                      v-b-tooltip.hover
                      style="margin-top: -5px"
                      title="If your markets do not have these 4 types of Read product, and do not want to use the Soltes calculator, delete Read and add as a standard E-Life product."
                      class="btn-tooltip"
                    >
                      <img
                        style="width: 20px; height: 20px"
                        src="@/assets/icons/tooltip.png"
                      />
                    </b-button>
                  </td>
                  <td>
                    <CustomEditor v-model="product.consumerName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.description" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.warningText" class-name="form-control" />
                  </td>
                  <td style="max-width: 250px">
                    <a
                      style="word-wrap: break-word"
                      :href="product.consumerUrl"
                      class="link-primary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >{{ product.consumerUrl }}</a>
                  </td>
                  <td v-show="product.ecpLink == 'Enter password'"></td>
                  <td v-show="product.ecpLink != 'Enter password'"></td>
                  <template v-if="product.icon">
                    <td>
                      <div
                        :id="`popover-icon-${seriesActionType}-productsLevel2-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.icon" />
                      </div>
                      <IconPopover
                        :product-type="`${seriesActionType}-productsLevel2`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.icon"
                      />
                    </td>
                  </template>
                  <template v-if="product.image">
                    <td>
                      <div
                        :id="`popover-image-${seriesActionType}-productsLevel2-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.image" />
                      </div>
                      <ImagePopover
                        :product-type="`${seriesActionType}-productsLevel2`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.image"
                      />
                    </td>
                  </template>
                </tr>
                <tr v-for="(product, index) in seriesCategory.productsLevel3" :key="product.id">
                  <td></td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="true" />
                      <span class="checkmark" /> Disable this lens type
                    </label>
                  </td>
                  <td>
                    <CustomEditor v-model="product.name" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.consumerName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.description" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor v-model="product.warningText" class-name="form-control" />
                  </td>
                  <td style="max-width: 250px">
                    <a
                      style="word-wrap: break-word"
                      :href="product.consumerUrl"
                      class="link-primary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >{{ product.consumerUrl }}</a>
                  </td>
                  <td v-show="product.ecpLink == 'Enter password'">
                    {{ product.ecpPassword }}
                  </td>
                  <td v-show="product.ecpLink != 'Enter password'">
                    {{ product.ecpLink }}
                  </td>
                  <template v-if="product.icon">
                    <td>
                      <div
                        :id="`popover-icon-${seriesActionType}-productsLevel3-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.icon" />
                      </div>
                      <IconPopover
                        :product-type="`${seriesActionType}-productsLevel3`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.icon"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                  <template v-if="product.image">
                    <td>
                      <div
                        :id="`popover-image-${seriesActionType}-productsLevel3-${index}`"
                        class="icon-img"
                      >
                        <img :src="IMAGE_API_PATH + product.image" />
                      </div>
                      <ImagePopover
                        :product-type="`${seriesActionType}-productsLevel3`"
                        :current-row-index="index"
                        :current-name="product.name"
                        :current-image="IMAGE_API_PATH + product.image"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="icon-img">
                        na
                      </div>
                    </td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="(product, index) in seriesCategory.products"
                  :key="product.id"
                  :class="{ 'bold-border': index === 0 }"
                >
                  <td
                    v-if="index === 0"
                    :rowspan="seriesCategory.products.length + 1"
                    class="s"
                  >
                    <CustomEditor 
                      v-model="seriesCategory.name"
                      type="textarea"
                      class-name="form-control category-name"
                      :rows="3"
                      :maxlength="26"
                    />
                  </td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.name"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="35"
                    />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="product.canNotBeDeleted && !product.isDisabled" />
                      <span class="checkmark" /> Disable this lens
                    </label>
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.consumerName"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="80"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.description"
                      type="textarea"
                      class-name="form-control"
                      :rows="5"
                      :maxlength="145"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.warningText"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="50"
                    />
                  </td>
                  <td></td>
                  <td>
                    <select v-model="product.ecpLink" class="form-control">
                      <option
                        v-for="ecpLink in ecpLinks"
                        :key="ecpLink"
                        :value="ecpLink"
                      >
                        {{ ecpLink }}
                      </option>
                    </select>
                    <b-row
                      v-show="product.ecpLink == 'Enter password'"
                      style="margin: 5px 0 0 0; padding: 0"
                    >
                      <b-col cols="3" style="margin: auto 0; padding: 0">
                        Pw:
                      </b-col>
                      <b-col cols="9" style="margin: 0; padding: 0">
                        <input
                          v-model="product.ecpPassword"
                          style="padding: 2px"
                          type="text"
                          class="form-control"
                        />
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.icon"
                        :src="IMAGE_API_PATH + product.icon"
                        @click="
                          iconOnClick(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="
                          iconOnClick(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <input
                        v-show="false"
                        ref="fileInput"
                        type="file"
                        @change="chooseIconWithWireframe($event,1)"
                      />
                      <button
                        id="falseinput"
                        style="
                          background-color: white;
                          border: none;
                          height: 40px;
                        "
                        @click="
                          iconOnClick(index);
                          changeImageActionType = seriesActionType;
                        "
                      >
                        <template v-if="product.id || product.icon">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.icon"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                    </div>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.image"
                        :src="IMAGE_API_PATH + product.image"
                        @click="
                          imageOnClick(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="
                          imageOnClick(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <input
                        v-show="false"
                        ref="fileInputImage"
                        type="file"
                        @change="chooseImageWithWireframe($event,1)"
                      />
                      <button
                        id="falseinput"
                        style="
                          background-color: white;
                          border: none;
                          height: 40px;
                        "
                        @click="
                          imageOnClick(index);
                          changeImageActionType = seriesActionType;
                        "
                      >
                        <template v-if="product.id || product.image">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.image"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                      <b-icon
                        v-show="!product.canNotBeDeleted && product.productType === null"
                        class="pointer"
                        icon="x-circle"
                        variant="danger"
                        @click="
                          removeProductElifeSeries(index, product);
                          removeActionType = seriesActionType;
                        "
                      />
                    </div>
                  </td>
                </tr>
                <!-- elife edit level 2 -->
                <tr v-for="(product) in seriesCategory.productsLevel2" :key="product.id">
                  <td v-if="seriesCategory.productsLevel3.length > 0">
                    <CustomEditor 
                      v-model="product.name"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="35"
                    />
                  </td>
                  <td v-if="seriesCategory.productsLevel3.length > 0" colspan="4" style="text-align: left; font-size: 17px; font-weight: bold;">
                    <b-button
                      v-b-tooltip.hover
                      style="margin-top: -5px"
                      title="If your markets do not have these 4 types of Read product, and do not want to use the Soltes calculator, delete Read and add as a standard E-Life product."
                      class="btn-tooltip"
                    >
                      <img
                        style="width: 20px; height: 20px"
                        src="@/assets/icons/tooltip.png"
                      />
                    </b-button>
                  </td>
                  <td>
                    <div class="editor"></div>
                  </td>
                  <td>
                    <div class="editor"></div>
                  </td>
                  <td>
                    <div class="editor"></div>
                  </td>
                  <td></td>
                </tr>
                <tr v-for="(product, index) in seriesCategory.productsLevel3" :key="product.id">
                  <td></td>
                  <td>
                    <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                    <label class="check-box">
                      <input v-model="product.isDisabled" type="checkbox" :disabled="isLensTypeDisabled(index, true)" />
                      <span class="checkmark" /> Disable this lens type
                    </label>
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.name"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="35"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.consumerName"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="80"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.description"
                      type="textarea"
                      class-name="form-control"
                      :rows="5"
                      :maxlength="145"
                    />
                  </td>
                  <td>
                    <CustomEditor 
                      v-model="product.warningText"
                      type="textarea"
                      class-name="form-control"
                      :rows="2"
                      :maxlength="50"
                    />
                  </td>
                  <td></td>
                  <td>
                    <select v-model="product.ecpLink" class="form-control">
                      <option
                        v-for="ecpLink in ecpLinks"
                        :key="ecpLink"
                        :value="ecpLink"
                      >
                        {{ ecpLink }}
                      </option>
                    </select>
                    <b-row
                      v-show="product.ecpLink == 'Enter password'"
                      style="margin: 5px 0 0 0; padding: 0"
                    >
                      <b-col cols="3" style="margin: auto 0; padding: 0">
                        Pw:
                      </b-col>
                      <b-col cols="9" style="margin: 0; padding: 0">
                        <input
                          v-model="product.ecpPassword"
                          style="padding: 2px"
                          type="text"
                          class="form-control"
                        />
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.icon"
                        :src="IMAGE_API_PATH + product.icon"
                        @click="
                          iconOnClickLevel3(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="
                          iconOnClickLevel3(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <input
                        v-show="false"
                        ref="fileInputLevel3"
                        type="file"
                        @change="chooseIconWithWireframe($event,3)"
                      />
                      <button
                        id="falseinput"
                        style="
                          background-color: white;
                          border: none;
                          height: 40px;
                        "
                        @click="
                          iconOnClickLevel3(index);
                          changeImageActionType = seriesActionType;
                        "
                      >
                        <template v-if="product.id || product.icon">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.icon"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                    </div>
                  </td>
                  <td>
                    <div class="icon-img">
                      <img
                        v-if="product.image"
                        :src="IMAGE_API_PATH + product.image"
                        @click="
                          imageOnClickLevel3(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <img
                        v-else
                        v-show="product.id"
                        src="@/assets/icons/image.png"
                        @click="
                          imageOnClickLevel3(index);
                          changeImageActionType = seriesActionType;
                        "
                      />
                      <input
                        v-show="false"
                        ref="fileInputImageLevel3"
                        type="file"
                        @change="chooseImageWithWireframe($event,3)"
                      />
                      <button
                        id="falseinput"
                        style="
                          background-color: white;
                          border: none;
                          height: 40px;
                        "
                        @click="
                          imageOnClickLevel3(index);
                          changeImageActionType = seriesActionType;
                        "
                      >
                        <template v-if="product.id || product.image">
                          <img src="@/assets/icons/replace.png" />
                        </template>
                        <template v-else>
                          <img
                            v-show="!product.image"
                            src="@/assets/icons/add.png"
                          />
                        </template>
                      </button>
                      <span id="selected_filename" />
                      <b-icon
                        v-show="false && !product.canNotBeDeleted"
                        class="pointer"
                        icon="x-circle"
                        variant="danger"
                        @click="
                          removeProductElifeSeries(index, product);
                          removeActionType = seriesActionType;
                        "
                      />
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-show="editable" class="table-borderless">
                <td />
                <td>
                  <b-button
                    variant="btn btn-info"
                    style="
                      margin-bottom: 10px;
                      width: 100%;
                      background: #006db6;
                      color: white;
                    "
                    @click="addSeriesProduct()"
                  >
                    + add more products
                  </b-button>
                </td>
                <td v-if="!hasSubCheckButtonAddMoreSoltesWideProduct">
                  <b-button
                    variant="btn btn-info"
                    style="
                      margin-bottom: 10px;
                      background: #006db6;
                      color: white;
                    "
                    @click="addELifeSeriesSoltesWideNeoProducts()"
                  >
                    + add more Soltes wide product
                  </b-button>
                </td>
              </tr>
            </template>
            <!--#########################- VOILA CATEGORY END --#######################################################--->

            <!--######################### COATING CATEGORY ############################################################--->
            <!-- list coatingCategory -->
            <template v-if="coatingCategory.products.length == 0">
              <tr class="bold-border">
                <td :rowspan="1 + editable" class="s">
                  <CustomEditor 
                    v-model="coatingCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    v-show="coatingCategory.image"
                    :id="`popover-category-${coatingActionType}-99`"
                    class="category-img"
                    :src="IMAGE_API_PATH + coatingCategory.image"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = coatingActionType;
                    "
                  />
                  <CategoryImagePopover
                    :product-type="coatingActionType"
                    :current-row-index="99"
                    :current-name="coatingCategory.name"
                    :current-image="IMAGE_API_PATH + coatingCategory.image"
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    v-show="editable"
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = coatingActionType;
                    "
                  >
                    <template v-if="coatingCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" />
                </td>
              </tr>
            </template>
            <!-- liststart -->
            <template v-if="!editable">
              <tr
                v-for="(product, index) in coatingCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="coatingCategory.products.length"
                  class="s"
                >
                  <CustomEditor 
                    v-model="coatingCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    :id="`popover-category-${coatingActionType}-${index}`"
                    class="category-img"
                    :src="IMAGE_API_PATH + coatingCategory.image"
                  />
                  <CategoryImagePopover
                    :product-type="coatingActionType"
                    :current-row-index="index"
                    :current-name="coatingCategory.name"
                    :current-image="IMAGE_API_PATH + coatingCategory.image"
                  />
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.name" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.consumerName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.description" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.warningText" class-name="form-control" />
                </td>
                <td style="max-width: 250px">
                  <a
                    style="word-wrap: break-word"
                    :href="product.consumerUrl"
                    class="link-primary"
                    target="_blank"
                    rel="noreferrer noopener"
                  >{{ product.consumerUrl }}</a>
                </td>
                <td v-show="product.ecpLink == 'Enter password'">
                  {{ product.ecpPassword }}
                </td>
                <td v-show="product.ecpLink != 'Enter password'">
                  {{ product.ecpLink }}
                </td>
                <template v-if="product.icon">
                  <td>
                    <div
                      :id="`popover-icon-${coatingActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.icon" />
                    </div>
                    <IconPopover
                      :product-type="coatingActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.icon"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
                <template v-if="product.image">
                  <td>
                    <div
                      :id="`popover-image-${coatingActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.image" />
                    </div>
                    <ImagePopover
                      :product-type="coatingActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.image"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
              </tr>
            </template>
            <!-- end list coatingCategory -->

            <!-- editable coatingCategory -->
            <template v-else>
              <tr
                v-for="(product, index) in coatingCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="coatingCategory.products.length + 1"
                  class="s"
                >
                  <CustomEditor 
                    v-model="coatingCategory.name"
                    type="textarea"
                    class-name="form-control category-name"
                    :rows="3"
                    :maxlength="26"
                  />
                  <!-- <br /> -->
                  <span>Image:</span>
                  <img
                    v-show="coatingCategory.image"
                    class="category-img"
                    :src="IMAGE_API_PATH + coatingCategory.image"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = coatingActionType;
                    "
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = coatingActionType;
                    "
                  >
                    <template v-if="coatingCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" />
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.name"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="35"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.consumerName"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="80"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.description"
                    type="textarea"
                    class-name="form-control"
                    :rows="5"
                    :maxlength="145"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.warningText"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="50"
                  />
                </td>
                <td>
                  <textarea-autosize
                    v-model="product.consumerUrl"
                    type="url"
                    class="form-control"
                  />
                </td>
                <td>
                  <select v-model="product.ecpLink" class="form-control">
                    <option
                      v-for="ecpLink in ecpLinks"
                      :key="ecpLink"
                      :value="ecpLink"
                    >
                      {{ ecpLink }}
                    </option>
                  </select>
                  <b-row
                    v-show="product.ecpLink == 'Enter password'"
                    style="margin: 5px 0 0 0; padding: 0"
                  >
                    <b-col cols="3" style="margin: auto 0; padding: 0">
                      Pw:
                    </b-col>
                    <b-col cols="9" style="margin: 0; padding: 0">
                      <input
                        v-model="product.ecpPassword"
                        style="padding: 2px"
                        type="text"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.icon"
                      :src="IMAGE_API_PATH + product.icon"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = coatingActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = coatingActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInput"
                      type="file"
                      @change="chooseIconWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        iconOnClick(index);
                        changeImageActionType = coatingActionType;
                      "
                    >
                      <template v-if="product.id || product.icon">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.icon"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                  </div>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.image"
                      :src="IMAGE_API_PATH + product.image"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = coatingActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = coatingActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInputImage"
                      type="file"
                      @change="chooseImageWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        imageOnClick(index);
                        changeImageActionType = coatingActionType;
                      "
                    >
                      <template v-if="product.id || product.image">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.image"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                    <b-icon
                      v-show="!product.canNotBeDeleted"
                      class="pointer"
                      icon="x-circle"
                      variant="danger"
                      @click="
                        removeProduct(index, product);
                        removeActionType = coatingActionType;
                      "
                    />
                  </div>
                </td>
              </tr>
            </template>
            <!-- end editable coatingCategory-->

            <!-- add button coatingCategory-->
            <tr v-show="editable" class="table-borderless">
              <td />
              <td>
                <b-button
                  variant="btn btn-info"
                  style="
                    margin-bottom: 10px;
                    width: 100%;
                    background: #006db6;
                    color: white;
                  "
                  @click="addCoatingProduct()"
                >
                  + add more products
                </b-button>
              </td>
            </tr>
            <!-- end add button voilaCategory -->

            <!--#########################- VOILA CATEGORY END --#######################################################--->

            <!--######################### TINT CATEGORY ##############################################################--->
            <!-- list tintCategory -->
            <template v-if="tintCategory.products.length == 0">
              <tr class="bold-border">
                <td :rowspan="1 + editable" class="s">
                  <CustomEditor 
                    v-model="tintCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <br />
                  <!-- <span>Image:</span>
                  <img
                    v-show="tintCategory.image"
                    :id="`popover-category-${tintActionType}-99`"
                    class="category-img"
                    :src="IMAGE_API_PATH + tintCategory.image"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = tintActionType;
                    "
                  />
                  <CategoryImagePopover
                    :product-type="tintActionType"
                    :current-row-index="99"
                    :current-name="tintCategory.name"
                    :current-image="IMAGE_API_PATH + tintCategory.image"
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    v-show="editable"
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(0);
                      changeImageActionType = tintActionType;
                    "
                  >
                    <template v-if="tintCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" /> -->
                </td>
              </tr>
            </template>
            <!-- liststart -->
            <template v-if="!editable">
              <tr
                v-for="(product, index) in tintCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="tintCategory.products.length"
                  class="s"
                >
                  <CustomEditor 
                    v-model="tintCategory.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <!-- <br /> -->
                  <!-- <span>Image:</span>
                  <img
                    :id="`popover-category-${tintActionType}-${index}`"
                    class="category-img"
                    :src="IMAGE_API_PATH + tintCategory.image"
                  />
                  <CategoryImagePopover
                    :product-type="tintActionType"
                    :current-row-index="index"
                    :current-name="tintCategory.name"
                    :current-image="IMAGE_API_PATH + tintCategory.image"
                  /> -->
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.name" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.consumerName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.description" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor v-model="product.warningText" class-name="form-control" />
                </td>
                <td style="max-width: 250px">
                  <a
                    style="word-wrap: break-word"
                    :href="product.consumerUrl"
                    class="link-primary"
                    target="_blank"
                    rel="noreferrer noopener"
                  >{{ product.consumerUrl }}</a>
                </td>
                <td v-show="product.ecpLink == 'Enter password'">
                  {{ product.ecpPassword }}
                </td>
                <td v-show="product.ecpLink != 'Enter password'">
                  {{ product.ecpLink }}
                </td>
                <template v-if="product.icon">
                  <td>
                    <div
                      :id="`popover-icon-${tintActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.icon" />
                    </div>
                    <IconPopover
                      :product-type="tintActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.icon"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
                <template v-if="product.image">
                  <td>
                    <div
                      :id="`popover-image-${tintActionType}-${index}`"
                      class="icon-img"
                    >
                      <img :src="IMAGE_API_PATH + product.image" />
                    </div>
                    <ImagePopover
                      :product-type="tintActionType"
                      :current-row-index="index"
                      :current-name="product.name"
                      :current-image="IMAGE_API_PATH + product.image"
                    />
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div class="icon-img">
                      na
                    </div>
                  </td>
                </template>
              </tr>
            </template>
            <!-- end list tintCategory-->

            <!-- editable tintCategory-->
            <template v-else>
              <tr
                v-for="(product, index) in tintCategory.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="tintCategory.products.length + 1"
                  class="s"
                >
                  <CustomEditor 
                    v-model="tintCategory.name"
                    type="textarea"
                    class-name="form-control category-name"
                    :rows="3"
                    :maxlength="26"
                  />
                  <br />
                  <!-- <span class="category-name">{{ tintCategory.name }}</span><br /> -->
                  <!-- <span>Image:</span>
                  <img
                    v-show="tintCategory.image"
                    class="category-img"
                    :src="IMAGE_API_PATH + tintCategory.image"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = tintActionType;
                    "
                  />
                  <input
                    v-show="false"
                    ref="fileInputCategory"
                    type="file"
                    @change="chooseCategoryImangeWithWireframe($event)"
                  />
                  <button
                    id="falseinput"
                    style="background-color: white; border: none; height: 40px"
                    @click="
                      categoryImageOnClick(index);
                      changeImageActionType = tintActionType;
                    "
                  >
                    <template v-if="tintCategory.image">
                      <img src="@/assets/icons/replace.png" />
                    </template>
                    <template v-else>
                      <img src="@/assets/icons/add.png" />
                    </template>
                  </button>
                  <span id="selected_filename" /> -->
                </td>
                <td>
                  <CustomEditor v-model="product.subCategoryName" class-name="form-control" />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.name"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="35"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.consumerName"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="80"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.description"
                    type="textarea"
                    class-name="form-control"
                    :rows="5"
                    :maxlength="145"
                  />
                </td>
                <td>
                  <CustomEditor 
                    v-model="product.warningText"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="50"
                  />
                </td>
                <td>
                  <textarea-autosize
                    v-model="product.consumerUrl"
                    type="url"
                    class="form-control"
                  />
                </td>
                <td>
                  <select v-model="product.ecpLink" class="form-control">
                    <option
                      v-for="ecpLink in ecpLinks"
                      :key="ecpLink"
                      :value="ecpLink"
                    >
                      {{ ecpLink }}
                    </option>
                  </select>
                  <b-row
                    v-show="product.ecpLink == 'Enter password'"
                    style="margin: 5px 0 0 0; padding: 0"
                  >
                    <b-col cols="3" style="margin: auto 0; padding: 0">
                      Pw:
                    </b-col>
                    <b-col cols="9" style="margin: 0; padding: 0">
                      <input
                        v-model="product.ecpPassword"
                        style="padding: 2px"
                        type="text"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.icon"
                      :src="IMAGE_API_PATH + product.icon"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = tintActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        iconOnClick(index);
                        changeImageActionType = tintActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInput"
                      type="file"
                      @change="chooseIconWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        iconOnClick(index);
                        changeImageActionType = tintActionType;
                      "
                    >
                      <template v-if="product.id || product.icon">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.icon"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                  </div>
                </td>
                <td>
                  <div class="icon-img">
                    <img
                      v-if="product.image"
                      :src="IMAGE_API_PATH + product.image"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = tintActionType;
                      "
                    />
                    <img
                      v-else
                      v-show="product.id"
                      src="@/assets/icons/image.png"
                      @click="
                        imageOnClick(index);
                        changeImageActionType = tintActionType;
                      "
                    />
                    <input
                      v-show="false"
                      ref="fileInputImage"
                      type="file"
                      @change="chooseImageWithWireframe($event,1)"
                    />
                    <button
                      id="falseinput"
                      style="
                        background-color: white;
                        border: none;
                        height: 40px;
                      "
                      @click="
                        imageOnClick(index);
                        changeImageActionType = tintActionType;
                      "
                    >
                      <template v-if="product.id || product.image">
                        <img src="@/assets/icons/replace.png" />
                      </template>
                      <template v-else>
                        <img
                          v-show="!product.image"
                          src="@/assets/icons/add.png"
                        />
                      </template>
                    </button>
                    <span id="selected_filename" />
                    <b-icon
                      v-show="!product.canNotBeDeleted"
                      class="pointer"
                      icon="x-circle"
                      variant="danger"
                      @click="
                        removeProduct(index, product);
                        removeActionType = tintActionType;
                      "
                    />
                  </div>
                </td>
              </tr>
            </template>
            <!-- end editable tintCategory-->

            <!-- add button tintCategory-->
            <tr v-show="editable" class="table-borderless">
              <td />
              <!-- <td>
                <b-button
                  variant="btn btn-info"
                  style="  margin-bottom: 10px; width: 100%; background: #006DB6; color: white"
                  @click="addTintProduct()"
                >
                  + add more products
                </b-button>
              </td> -->
              <td>
                <b-button
                  variant="btn btn-info"
                  style="
                    margin-bottom: 10px;
                    width: 100%;
                    background: #006db6;
                    color: white;
                  "
                  @click="addTintPolashadeProduct()"
                >
                  + add Polashade
                </b-button>
              </td>
              <td>
                <b-button
                  variant="btn btn-info"
                  style="margin-bottom: 10px; background: #006db6; color: white"
                  @click="addTintTransitionseProduct()"
                >
                  + add Transitions
                </b-button>
              </td>
            </tr>
            <!-- end add button tintCategory -->
            <!--#########################- TINT CATEGORY END --#######################################################--->
            <!-- liststart conventional Lens -->
            <template v-if="!editable">
              <tr
                v-for="(product, index) in conventionalLens.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="conventionalLens.products.length"
                  :colspan="2"
                  class="s"
                >
                  <CustomEditor 
                    v-model="conventionalLens.name"
                    main-class-name="category-name"
                    class-name="form-control"
                  />
                  <br />
                </td>
                <td>
                  <CustomEditor v-model="product.name" class-name="w-100 form-control" />
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
              </tr>
            </template>
            <!-- end list conventional Lens -->
            <!-- editable conventional Lens -->
            <template v-else>
              <tr
                v-for="(product, index) in conventionalLens.products"
                :key="product.id"
                :class="{ 'bold-border': index === 0 }"
              >
                <td
                  v-if="index === 0"
                  :rowspan="2"
                  :colspan="2"
                  class="s"
                >
                  <CustomEditor 
                    v-model="conventionalLens.name"
                    type="textarea"
                    class-name="form-control category-name"
                    :rows="3"
                    :maxlength="26"
                  />
                  <br />
                </td>
                
                <td>
                  <CustomEditor 
                    v-model="product.name"
                    type="textarea"
                    class-name="form-control"
                    :rows="2"
                    :maxlength="35"
                  />
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
              </tr>
            </template>
            <!-- end editable conventinal lens-->
          </tbody>
        </table>
      </div>
      <!-- Space end page -->
      <div style="height: 50px"></div>
    </div>
    <!--######################### The Modal SAVE ALL ###########################################################################-->
    <div v-if="showModalSaveAll">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>
                      All changes will be saved.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        saveAll();
                        showModalSaveAll = false;
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="cancelSaveAllEditable();"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal SAVE All END ###########################################################################-->

    <!--######################### The Modal CANCEL ###########################################################################-->
    <div v-if="showModalCancelChange">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>
                      All changes will be removed.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        reloadPage();
                        showModalCancelChange = false;
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="cancelEditable();"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal CANCEL END ###########################################################################-->

    <!--######################### The Modal DELETE ###########################################################################-->
    <div v-if="showModalDelete">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div v-if="!deleteElifeProRead" class="body-text-modal">
                    <p>
                      {{ titleDeletedProduct }} will be deleted.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div
                    v-if="deleteElifeProRead"
                    class="body-text-modal-elife-pro-read"
                  >
                    <p>
                      E-Life Pro Read cannot be deleted individually. when one
                      E-Life Pro Read is deleted, all 4 E-Life Pro Reads are
                      deleted.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        showModalDelete = false;
                        removeConfirm();
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showModalDelete = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showModalDelete">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div v-if="!deleteElifeSeriesRead" class="body-text-modal">
                    <p>
                      {{ titleDeletedProduct }} will be deleted.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div
                    v-if="deleteElifeSeriesRead"
                    class="body-text-modal-elife-pro-read"
                  >
                    <p>
                      E-Life Series cannot be deleted individually. when one
                      E-Life Series is deleted, all 4 E-Life Series are
                      deleted.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        showModalDelete = false;
                        removeConfirm();
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showModalDelete = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal DELETE END ###########################################################################-->
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="showError">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>{{ message }}<br /></p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showError = false"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
// Voila category is E-life category.
// Specs was changed many times. So somewhere you can see voila, somewhere is E-life
// Keep awake that they are same

import { fixHeaderTable } from "../../common/CommonUtils";
import CustomEditor from "../../components/custom/CustomEditor";
import { CONTEXT_PATH, LOAD_IMAGE_API_PATH } from "../../constants/Paths";
import CategoryService from "../../service/CategoryService";
import ConfigurationService from "../../service/ConfigurationService";
import ProductService from "../../service/ProductService";
import UploadFileService from "../../service/UploadFileService";
import CategoryImagePopover from "../common/CategoryImagePopover.vue";
import IconPopover from "../common/IconPopover.vue";
import ImagePopover from "../common/ImagePopover.vue";

export default {
  name: "SurveyCreation",
  components: {
    IconPopover,
    ImagePopover,
    CategoryImagePopover,
    CustomEditor
  },
  data() {
    return {
      IMAGE_API_PATH: CONTEXT_PATH + LOAD_IMAGE_API_PATH,
      progress: 0,
      showError: false,
      selected: "E_LIFE",
      configuration: {},
      editable: (this.$store.state.survey.isSurveyEditing = false) && false,
      productType: "",
      currentRowIndex: null,
      currentName: "",
      currentImage: "",
      options: [
        { text: "E-Life Pro", value: "E_LIFE_PRO" },
        { text: "e-Life", value: "E_LIFE" },
      ],
      ecpLinks: [],
      categories: [],
      singleVisionCategory: {},
      hasSubVisionCategoryProducts: false,

      progressiveLensCategory: {},
      hasSubProgressiveLensnCategoryProducts: false,

      voilaCategory: {},
      hasSubVoilaCategoryProducts: false,
      eLifeProSubCategoryMap: {},
      hasSubAddElifeProReadProduct: false,

      seriesCategory: {},
      hasSubSeriesCategoryProducts: false,
      eLifeSeriesSubCategoryMap: {},
      hasSubCheckButtonAddMoreSoltesWideProduct: false,

      coatingCategory: {},
      hasSubCoatingCategoryProducts: false,

      tintCategory: {},
      conventionalLens: {},
      hasTintPolashadeProduct: false,
      hasTintTransitionsProduct: false,
      subTintPolashadeId: null,
      subTintPolashadeName: null,
      subTintTransitionsId: null,
      subTintTransitionsName: null,

      bulkProducts: [],
      deletedProductIds: [],

      showModalSaveAll: false,
      showModalDelete: false,
      titleDeletedProduct: "",
      showModalCancelChange: false,
      deleteElifeProRead: false,
      deleteElifeSeriesRead: false,

      changeECPLinkActionType: "",
      removeActionType: "",
      changeImageActionType: "",
      changeImageRownIndex: null,
      singleVisionActionType: "SINGLE_VISION",
      progressiveActionType: "PROGRESSIVE_LENS",
      voilaActionType: "VOILA",
      seriesActionType: "SERIES",
      coatingActionType: "COATING",
      tintActionType: "TINT",
      removeIndex: 0,
      removeProductItem: {},
    };
  },
  computed: {
    countryAndLanguage() {
      return this.$store.state.survey.countryAndLanguage;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "edit-th-content"
            : "normal-th-content bg-light"
    },
  },
  mounted() {
    this.retrieveConfiguration();
    this.retrieveProductsByCategory();
  },
  updated() {
    fixHeaderTable("table-1", "header-nav", this.editable);
  },
  methods: {
    retrieveConfiguration() {
      let surveyId = this.$store.state.survey.id;
      this.surveyId = surveyId <= 0 ? 1 : surveyId;
      ConfigurationService.select("RECOMMENDATION_PRODUCT_TYPE", surveyId)
        .then((response) => {
          var result = response.data.result;
          console.log(result);
          this.configuration = result.configuration;
          this.selected = this.configuration.configValue;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveProductsByCategory() {
      let loader = this.$loading.show();
      let surveyId = this.$store.state.survey.id;
      this.surveyId = surveyId <= 0 ? 1 : surveyId;
      CategoryService.listWithProducts(this.surveyId)
        .then((response) => {
          var result = response.data.result;
          console.log(result);
          if (result) {
            this.ecpLinks = result.ecpLinks;

            if (result.singleVisionCategory) {
              this.singleVisionCategory = result.singleVisionCategory;
              this.hasSubVisionCategoryProducts = this.singleVisionCategory.hasSubCategoryProducts;
            }
            if (result.progressiveLensCategory) {
              this.progressiveLensCategory = result.progressiveLensCategory;
              this.hasSubProgressiveLensnCategoryProducts = this.progressiveLensCategory.hasSubCategoryProducts;
            }
            if (result.voilaCategory) {
              this.voilaCategory = result.voilaCategory;
              this.eLifeProSubCategoryMap = result.eLifeProSubCategoryMap;
              this.hasSubVoilaCategoryProducts = this.voilaCategory.hasSubCategoryProducts;
              this.hasSubAddElifeProReadProduct = this.voilaCategory.hasSubCategoryProducts;
            }
            if (result.seriesCategory) {
              this.seriesCategory = result.seriesCategory;
              this.eLifeSeriesSubCategoryMap = result.eLifeSeriesSubCategoryMap;
              this.hasSubSeriesCategoryProducts = this.seriesCategory.hasSubCategoryProducts;
              this.hasSubCheckButtonAddMoreSoltesWideProduct = this.seriesCategory.hasSubCategoryProducts;
            }
            if (result.coatingCategory) {
              this.coatingCategory = result.coatingCategory;
              this.hasSubCoatingCategoryProducts =
                this.coatingCategory.hasSubCategoryProducts;
            }
            if (result.tintCategory) {
              this.tintCategory = result.tintCategory;
            }
            if (result.conventionalLens)
              this.conventionalLens = result.conventionalLens;
            this.setupTintSubCategoryInformation();

            this.bulkProducts = [];
            this.deletedProductIds = [];
          }

          loader.hide();
        })
        .catch((e) => {
          loader.hide();
          console.log(e);
        });
    },
    setupTintSubCategoryInformation() {
      var subCategories = this.tintCategory.childCategories;
      var products = this.tintCategory.products;
      if (subCategories != null) {
        for (let index = 0; index < subCategories.length; index++) {
          var subCategory = subCategories[index];

          if (subCategory.name === "Polashade") {
            for (
              let productIndex = 0;
              productIndex < products.length;
              productIndex++
            ) {
              const product = products[productIndex];
              if (product.subCategoryName === "Polashade") {
                this.hasTintPolashadeProduct = true;
                break;
              }
            }
            this.subTintPolashadeId = subCategory.id;
            this.subTintPolashadeName = subCategory.name;
          }

          if (subCategory.name === "Transitions") {
            for (
              let productIndex = 0;
              productIndex < products.length;
              productIndex++
            ) {
              const product = products[productIndex];
              if (product.subCategoryName === "Transitions") {
                this.hasTintTransitionsProduct = true;
                break;
              }
            }
            this.subTintTransitionsId = subCategory.id;
            this.subTintTransitionsName = subCategory.name;
          }
        }
      }
    },
    isLensTypeDisabled(index, isSeries) {
      if (isSeries) {
        // console.log(this.seriesCategory.productsLevel3);
        let product = this.seriesCategory.productsLevel3[index];
        if (product.isDisabled) {
          return false;
        }
        // if (product.canNotBeDeleted) {
          // return true;
        // }
        let hasEnabledLensType = false;
        for (let i = 0; i < this.seriesCategory.productsLevel3.length; i++) {
           if (!this.seriesCategory.productsLevel3[i].isDisabled) {
            if (hasEnabledLensType) {
              return false;
            }
            hasEnabledLensType = true;
           }
        }
        return true;
      }
      else {
        // console.log(this.voilaCategory.productsLevel3);
        let product = this.voilaCategory.productsLevel3[index];
        if (product.isDisabled) {
          return false;
        }
        // if (product.canNotBeDeleted) {
          // return true;
        // }
        let hasEnabledLensType = false;
        for (let i = 0; i < this.voilaCategory.productsLevel3.length; i++) {
           if (!this.voilaCategory.productsLevel3[i].isDisabled) {
            if (hasEnabledLensType) {
              return false;
            }
            hasEnabledLensType = true;
           }
        }
        return true;
      }
    },
    changeProductTypeRadio(value) {
      console.log("event " + value);
      this.selected = value;
      console.log("this.selected  " + this.selected);
      this.configuration.configValue = this.selected;
    },
    changeEditableState(state) {
      this.editable = state;
      this.$store.state.survey.isSurveyEditing = state;
      if (state === true) {
        this.$store.state.survey.editingScreenSaveFunc = this.saveAll;
      }
    },
    turnOnEditable() {
      this.changeEditableState(true);
    },
    
    cancelEditable() {
        this.showModalCancelChange = false;
    },
      cancelSaveAllEditable() {
          this.showModalSaveAll = false;
      },

    turnOffEditable() {
      this.changeEditableState(false);
      this.retrieveConfiguration();
      this.retrieveProductsByCategory();
    },

    reloadPage() {
      this.turnOffEditable();
    },

    checkMaxValue($event) {
      if (this.editValue != undefined && this.editValue.length >= this.validateMaxValue) {
        $event.preventDefault();
        return;
      }
    },

    onPaste($event) {
      const value = $event.clipboardData.getData("text");
      if (value.length >= this.validateMaxValue) {
        this.editValue = this.editValue.substring(199);
        $event.preventDefault();
        return;
      }
    },

    /**
     * Change ecpLink to password
     */
    onChangeEcpLink(event, index) {
      console.log(
        "onChangeEcpLink " +
          event.target.value +
          " index " +
          index +
          " changeECPLinkActionType " +
          this.changeECPLinkActionType
      );
    },

    /**
     * Get category Image click
     */
    categoryImageOnClick(index) {
      this.changeImageRownIndex = index;
      const elem = this.$refs.fileInputCategory[index];
      console.log("categoryImageOnClick" + index + " " + elem);
      elem.click();
    },

    /**
     * Get Icon click
     */
    iconOnClick(index) {
      this.changeImageRownIndex = index;
      const elem = this.$refs.fileInput[index];
      console.log("iconOnClick " + index + " " + elem);
      elem.click();
    },
    iconOnClickLevel3(index) {
        this.changeImageRownIndex = index;
        const elem = this.$refs.fileInputLevel3[index];
        console.log("iconOnClick " + index + " " + elem);
        elem.click();
    },

    /**
     * Get Image click
     */
    imageOnClick(index) {
      this.changeImageRownIndex = index;
      console.log("imageOnClick index" + index);
      const elem = this.$refs.fileInputImage[index];
      console.log(elem);
      elem.click();
    },
    imageOnClickLevel3(index) {
        this.changeImageRownIndex = index;
        console.log("imageOnClick index" + index);
        const elem = this.$refs.fileInputImageLevel3[index];
        console.log(elem);
        elem.click();
    },

    /**
     * Choose category image hanlder
     */
    chooseCategoryImangeWithWireframe(event) {
      console.log("chooseCategoryImangeWithWireframe ");
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.createCategoyImageWithWireframe(files[0], event.target);
    },

    /**
     * Choose icon hanlder
     */
    chooseIconWithWireframe(event, level) {
      console.log("chooseIconWithWireframe " + level);
      var files = event.target.files || event.dataTransfer.files;
      console.log(files.length);
      if (!files.length) return;
      this.createIconWithWireframe(files[0],event.target, level);
    },

    /**
     * Choose image hanlder
     */
    chooseImageWithWireframe(event,level) {
      console.log("chooseImageWithWireframe " + level);
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.createImageWithWireframe(files[0], event.target, level);
    },

    /**
     * Create category image with wire frame by category type
     */
    createCategoyImageWithWireframe(file, input) {
      UploadFileService.checkImageCategoryBeforeUpload(file, 'category')
      .then(() => {
        var suffix = file["type"];
      if (
        suffix !== "image/png" &&
        suffix !== "image/jpeg" &&
        suffix !== "image/gif"
      ) {
        console.log(
          "The image format is incorrect, please upload the file in .png, .jpeg, .gif  format"
        );
        this.message =
          "The image format is incorrect, please upload the file in .png, .jpeg, .gif format";
        this.showError = true;
        input.value = null;
        this.progress = 0;
        console.log(this.message);
        this.currentFile = undefined;
        return;
      }
      let loader = this.$loading.show();
      UploadFileService.uploadWithFileNamePrefix(
        file,
        "category_image",
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }
      )
        .then((response) => {
          loader.hide();
          var imageName = response.data.name;
          console.log(
            "this.changeImageActionType = " + this.changeImageActionType
          );
          if (this.changeImageActionType === this.singleVisionActionType) {
            this.singleVisionCategory.image = imageName;
          }
          if (this.changeImageActionType === this.progressiveActionType) {
            this.progressiveLensCategory.image = imageName;
          }
          if (this.changeImageActionType === this.voilaActionType) {
            this.voilaCategory.image = imageName;
          }
          if (this.changeImageActionType === this.seriesActionType) {
            this.seriesCategory.image = imageName;
          }
          if (this.changeImageActionType === this.coatingActionType) {
            this.coatingCategory.image = imageName;
          }
          if (this.changeImageActionType === this.tintActionType) {
            this.tintCategory.image = imageName;
          }
        })
        .catch((error) => {
          loader.hide();
          input.value = null;
          console.log("error" + error);
          this.message = error;
          this.showError = true;
          this.progress = 0;
          console.log(this.message);
          this.currentFile = undefined;
        });
      })
      .catch(() => {
        console.log(
          "The image size and/or dimension is over the limitation."
        );
        this.message =
          "The image size and/or dimension is over the limitation.";
        this.showError = true;
        this.progress = 0;
        console.log(this.message);
        input.value = null;
        this.currentFile = undefined;
        return;
      }) 
      
    },

    /**
     * Create icon with wire frame by category type
     */
    createIconWithWireframe(file, input, level) {
      UploadFileService.checkImageCategoryBeforeUpload(file, 'icon')
      .then(() => {
      var index = this.changeImageRownIndex;
      var suffix = file["type"];
      if (
        suffix !== "image/png" &&
        suffix !== "image/jpeg" &&
        suffix !== "image/gif"
      ) {
        console.log(
          "The image format is incorrect, please upload the file in .png, .jpeg, .gif  format"
          );
          this.message =
          "The image format is incorrect, please upload the file in .png, .jpeg, .gif format";
        input.value = null;
        this.showError = true;
        this.progress = 0;
        console.log(this.message);
        this.currentFile = undefined;
        return;
      }
      let loader = this.$loading.show();
      UploadFileService.uploadWithFileNamePrefix(
        file,
        "product_icon",
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }
      )
        .then((response) => {
          loader.hide();
          var imageName = response.data.name;
          console.log(
            "this.changeImageActionType = " +
              this.changeImageActionType +
              "index = " +
              index
          );
          if (this.changeImageActionType === this.singleVisionActionType) {
            this.singleVisionCategory.products[this.changeImageRownIndex].icon =
              imageName;
          }
          if (this.changeImageActionType === this.progressiveActionType) {
            this.progressiveLensCategory.products[index].icon = imageName;
          }
          if (this.changeImageActionType === this.voilaActionType && level == 1) {
            this.voilaCategory.products[index].icon = imageName;
          } else if (this.changeImageActionType === this.voilaActionType && level == 3) {
              this.voilaCategory.productsLevel3[index].icon = imageName;
          }
          if (this.changeImageActionType === this.seriesActionType && level == 1) {
            this.seriesCategory.products[index].icon = imageName;
          } else if (this.changeImageActionType === this.seriesActionType && level == 3) {
              this.seriesCategory.productsLevel3[index].icon = imageName;
          }
          if (this.changeImageActionType === this.coatingActionType) {
            this.coatingCategory.products[index].icon = imageName;
          }
          if (this.changeImageActionType === this.tintActionType) {
            this.tintCategory.products[index].icon = imageName;
          }
        })
        .catch((error) => {
          loader.hide();
          input.value = null;
          console.log("error" + error);
          this.message = error;
          this.showError = true;
          this.progress = 0;
          console.log(this.message);
          this.currentFile = undefined;
        });
      }).catch(() => {
        console.log(
          "The image size and/or dimension is over the limitation."
        );
        this.message =
          "The image size and/or dimension is over the limitation.";
        input.value = null;
        this.showError = true;
        this.progress = 0;
        console.log(this.message);
        this.currentFile = undefined;
        return;
      })
    },

    /**
     * Create imange with wire frame by category type
     */
    createImageWithWireframe(file, input, level) {
      UploadFileService.checkImageCategoryBeforeUpload(file, 'image')
      .then(() => {
      var index = this.changeImageRownIndex;
      var suffix = file["type"];
      if (
        suffix !== "image/png" &&
        suffix !== "image/jpeg" &&
        suffix !== "image/gif"
      ) {
        console.log(
          "The image format is incorrect, please upload the file in .png, .jpeg, .gif  format"
        );
        this.message =
          "The image format is incorrect, please upload the file in .png, .jpeg, .gif format";
        input.value = null;
        this.showError = true;
        this.progress = 0;
        console.log(this.message);
        this.currentFile = undefined;
        return;
      }
      let loader = this.$loading.show();
      UploadFileService.uploadWithFileNamePrefix(
        file,
        "product_image",
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }
      )
        .then((response) => {
          loader.hide();
          var imageName = response.data.name;
          console.log(
            "this.changeImageActionType = " +
              this.changeImageActionType +
              "index = " +
              index
          );
          if (this.changeImageActionType === this.singleVisionActionType) {
            this.singleVisionCategory.products[index].image = imageName;
          }
          if (this.changeImageActionType === this.progressiveActionType) {
            this.progressiveLensCategory.products[index].image = imageName;
          }
          if (this.changeImageActionType === this.voilaActionType && level == 1) {
            this.voilaCategory.products[index].image = imageName;
          } else if (this.changeImageActionType === this.voilaActionType && level == 3) {
              this.voilaCategory.productsLevel3[index].image = imageName;
          }
          if (this.changeImageActionType === this.seriesActionType && level == 1) {
            this.seriesCategory.products[index].image = imageName;
          } else if (this.changeImageActionType === this.seriesActionType && level == 3) {
              this.seriesCategory.productsLevel3[index].image = imageName;
          }
          if (this.changeImageActionType === this.coatingActionType) {
            this.coatingCategory.products[index].image = imageName;
          }
          if (this.changeImageActionType === this.tintActionType) {
            this.tintCategory.products[index].image = imageName;
          }
        })
        .catch((error) => {
          loader.hide();
          input.value = null;
          console.log("error" + error);
          this.message = error;
          this.showError = true;
          this.progress = 0;
          console.log(this.message);
          this.currentFile = undefined;
        });
      }).catch(() => {
        input.value = null;
        console.log(
          "The image size and/or dimension is over the limitation."
        );
        this.message =
          "The image size and/or dimension is over the limitation.";
        this.showError = true;
        this.progress = 0;
        console.log(this.message);
        this.currentFile = undefined;
        return;
      })
    },

    removeProduct(index, removeProduct) {
      this.showModalDelete = true;
      this.titleDeletedProduct = removeProduct.name;
      this.removeIndex = index;
      this.removeProductItem = removeProduct;

      var eLifeProActive = this.eLifeProSubCategoryMap.eLifeProActive;
      if (
        removeProduct.subCategoryId != null &&
        removeProduct.subCategoryId != eLifeProActive.id
      ) {
        this.deleteElifeProRead = true;
      } else {
        this.deleteElifeProRead = false;
      }
    },

    removeProductElifeSeries(index, removeProduct) {
      this.showModalDelete = true;
      this.titleDeletedProduct = removeProduct.name;
      this.removeIndex = index;
      this.removeProductItem = removeProduct;

      if (removeProduct.subCategoryId != null) {
        this.deleteElifeSeriesRead = true;
      } else {
        this.deleteElifeSeriesRead = false;
      }
    },

    removeConfirm() {
      console.log("this.removeActionType = " + this.removeActionType);
      if (this.removeActionType === this.singleVisionActionType) {
        this.removeSingleVisionProduct(this.removeIndex);
      }
      if (this.removeActionType === this.progressiveActionType) {
        this.removeProgressiveLensProduct(this.removeIndex);
      }
      if (this.removeActionType === this.voilaActionType) {
        this.removeVoilaProduct(this.removeProductItem, this.removeIndex);
      }
      if (this.removeActionType === this.seriesActionType) {
        this.removeSeriesProduct(this.removeProductItem, this.removeIndex);
      }
      if (this.removeActionType === this.coatingActionType) {
        this.removeCoatingProduct(this.removeIndex);
      }
      if (this.removeActionType === this.tintActionType) {
        this.removeTintProduct(this.removeProductItem, this.removeIndex);
      }
    },

    saveAll(chainFunc) {
      this.bulkProducts = this.singleVisionCategory.products;
      console.log(
        "this.singleVisionCategory.products" +
          this.singleVisionCategory.products
      );
      console.log("this.bulkProducts" + this.bulkProducts);
      this.bulkProducts = this.bulkProducts.concat(
        this.progressiveLensCategory.products
      );
      this.bulkProducts = this.bulkProducts.concat(this.voilaCategory.products);
      this.bulkProducts = this.bulkProducts.concat(this.voilaCategory.productsLevel2);
      this.bulkProducts = this.bulkProducts.concat(this.voilaCategory.productsLevel3);
      this.bulkProducts = this.bulkProducts.concat(this.seriesCategory.products);
      this.bulkProducts = this.bulkProducts.concat(this.seriesCategory.productsLevel2);
      this.bulkProducts = this.bulkProducts.concat(this.seriesCategory.productsLevel3);
      this.bulkProducts = this.bulkProducts.concat(
        this.coatingCategory.products
      );
      this.bulkProducts = this.bulkProducts.concat(this.tintCategory.products);
      this.bulkProducts = this.bulkProducts.concat(this.conventionalLens.products);


      var categoryDtos = [];
      categoryDtos.push({
        id: this.singleVisionCategory.id,
        name: this.singleVisionCategory.name,
        image: this.singleVisionCategory.image,
      });
      categoryDtos.push({
        id: this.progressiveLensCategory.id,
        name: this.progressiveLensCategory.name,
        image: this.progressiveLensCategory.image,
      });
      categoryDtos.push({
        id: this.voilaCategory.id,
        name: this.voilaCategory.name,
        image: this.voilaCategory.image,
      });
      categoryDtos.push({
        id: this.seriesCategory.id,
        name: this.seriesCategory.name,
        image: this.seriesCategory.image,
      });
      categoryDtos.push({
        id: this.coatingCategory.id,
        name: this.coatingCategory.name,
        image: this.coatingCategory.image,
      });
      categoryDtos.push({
        id: this.tintCategory.id,
        name: this.tintCategory.name,
        image: this.tintCategory.image,
      });
      categoryDtos.push({
        id: this.conventionalLens.id,
        name: this.conventionalLens.name,
        image: this.conventionalLens.image,
      });

      let surveyId = this.$store.state.survey.id;
      var data = {
        surveyId: surveyId,
        bulkProducts: this.bulkProducts,
        deletedProductIds: this.deletedProductIds,
        categoryRequestDtos: categoryDtos,
      };
      console.log("DATA INPUT: " + JSON.stringify(data));

      let chain1 = undefined;
      if (chainFunc !== undefined) {
        let completeCount = 0;
        chain1 = () => {
          completeCount ++;
          if (completeCount === 2)
            chainFunc();
        };
      }
      ProductService.saveAll(data)
        .then((response) => {
          console.log(response.data.result.numerEffectedProduct);
          this.changeEditableState(false);
          this.retrieveConfiguration();
          this.retrieveProductsByCategory();
          if (chain1 !== undefined)
            chain1()
        })
        .catch((e) => {
          console.log(e);
          this.changeEditableState(false);
          this.retrieveConfiguration();
          this.retrieveProductsByCategory();
        });
      this.surveyId = surveyId <= 0 ? 1 : surveyId;
      /** Save configuration */
      this.configuration.surveyId = this.surveyId;
      ConfigurationService.update(this.configuration)
        .then((response) => {
          var result = response.data.result;
          this.configuration = result.configuration;
          this.selected = this.configuration.configValue;
          console.log("this.selected after change " + this.selected);
          if (chain1 !== undefined)
            chain1()
        })
        .catch((e) => {
          console.log(e);
        });
    },

    addSingleVisionProduct() {
      this.singleVisionCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.singleVisionCategory.id,
        categoryName: this.singleVisionCategory.name,
        subCategoryId: null,
        subCategoryName: null,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
    },

    addRelaxseeNeoProduct() {
      var subCategory = this.singleVisionCategory.childCategories;
      if (subCategory != null && subCategory.length > 0) {
        var relaxseeNeoSubCategory = subCategory[0];
        console.log("relaxseeNeoSubCategory" + relaxseeNeoSubCategory);
        this.singleVisionCategory.products.push({
          id: null,
          name: "",
          description: "",
          consumerName: "",
          consumerUrl: "",
          ecpLink: "All package",
          warningText: "",
          icon: null,
          image: null,
          categoryId: this.singleVisionCategory.id,
          categoryName: this.singleVisionCategory.name,
          subCategoryId: relaxseeNeoSubCategory.id,
          subCategoryName: relaxseeNeoSubCategory.name,
          canNotBeDeleted: false,
          surveyId: this.$store.state.survey.id,
          isRelaxsee: false,
          isDisabled: false,
        });
        this.hasSubVisionCategoryProducts = true;
      }
    },

    removeSingleVisionProduct(index) {
      if (this.singleVisionCategory.products[index].id) {
        this.deletedProductIds = this.deletedProductIds.concat(
          this.singleVisionCategory.products[index].id
        );
      }
      this.singleVisionCategory.products.splice(index, 1);
      this.hasSubVisionCategoryProducts = false;
    },

    addProgressiveLensProduct() {
      this.progressiveLensCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.progressiveLensCategory.id,
        categoryName: this.progressiveLensCategory.name,
        subCategoryId: null,
        subCategoryName: null,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
    },
    addRelaxseeNeoProgressiveProduct() {
      var subCategory = this.progressiveLensCategory.childCategories;
      if (subCategory != null && subCategory.length > 0) {
        var relaxseeNeoProgressiveSubCategory = subCategory[0];
        console.log(
          "relaxseeNeoSubCategory" + relaxseeNeoProgressiveSubCategory
        );
        this.progressiveLensCategory.products.push({
          id: null,
          name: "",
          description: "",
          consumerName: "",
          consumerUrl: "",
          ecpLink: "All package",
          warningText: "",
          icon: null,
          image: null,
          categoryId: this.progressiveLensCategory.id,
          categoryName: this.progressiveLensCategory.name,
          subCategoryId: relaxseeNeoProgressiveSubCategory.id,
          subCategoryName: relaxseeNeoProgressiveSubCategory.name,
          canNotBeDeleted: false,
          surveyId: this.$store.state.survey.id,
          isRelaxsee: false,
          isDisabled: false,
        });
        this.hasSubProgressiveLensnCategoryProducts = true;
      }
    },

    removeProgressiveLensProduct(index) {
      console.log("index" + index);
      if (this.progressiveLensCategory.products[index].id) {
        this.deletedProductIds = this.deletedProductIds.concat(
          this.progressiveLensCategory.products[index].id
        );
      }
      this.progressiveLensCategory.products.splice(index, 1);
      this.hasSubProgressiveLensnCategoryProducts = false;
    },

    addVoilaProduct() {
      this.voilaCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.voilaCategory.id,
        categoryName: this.voilaCategory.name,
        subCategoryId: null,
        subCategoryName: null,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
    },

    addSeriesProduct() {
      this.seriesCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.seriesCategory.id,
        categoryName: this.seriesCategory.name,
        subCategoryId: null,
        subCategoryName: null,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
    },

    addELifeProReadProducts() {
      var eLifeProReads = this.eLifeProSubCategoryMap.eLifeProRead;
      if (eLifeProReads != null && eLifeProReads.length > 0) {
        for (let index = 0; index < eLifeProReads.length; index++) {
          const eLifeProRead = eLifeProReads[index];
          this.voilaCategory.productsLevel3.push({
            id: null,
            name: eLifeProRead.name,
            description: "",
            consumerName: "",
            consumerUrl: "",
            ecpLink: "All package",
            warningText: "",
            icon: null,
            image: null,
            categoryId: this.voilaCategory.id,
            categoryName: this.voilaCategory.name,
            subCategoryId: eLifeProRead.id,
            subCategoryName: eLifeProRead.name,
            canNotBeDeleted: false,
            surveyId: this.$store.state.survey.id,
            isRelaxsee: false,
            isDisabled: false,
            productType:index == 0 ? 'E_LIFE_PRO_READ':null,
          });
        }
        this.hasSubAddElifeProReadProduct = true;
      }
    },

    addELifeSeriesSoltesWideNeoProducts() {
      var eLifeSeriesReads = this.eLifeSeriesSubCategoryMap.eLifeSeriesRead;
      if (eLifeSeriesReads != null && eLifeSeriesReads.length > 0) {
        for (let index = 0; index < eLifeSeriesReads.length; index++) {
          const eLifeSeriesRead = eLifeSeriesReads[index];
          this.seriesCategory.productsLevel3.push({
            id: null,
            name: eLifeSeriesRead.name,
            description: "",
            consumerName: "",
            consumerUrl: "",
            ecpLink: "All package",
            warningText: "",
            icon: null,
            image: null,
            categoryId: this.voilaCategory.id,
            categoryName: this.voilaCategory.name,
            subCategoryId: eLifeSeriesRead.id,
            subCategoryName: eLifeSeriesRead.name,
            canNotBeDeleted: false,
            surveyId: this.$store.state.survey.id,
            isRelaxsee: false,
            isDisabled: false,
            productType:index == 0 ? 'SOLTES_WIDE_NEO':null,
          });
        }
        this.hasSubCheckButtonAddMoreSoltesWideProduct = true;
      }
    },

    addELifeProActiveProducts() {
      var eLifeProActive = this.eLifeProSubCategoryMap.eLifeProActive;
      if (eLifeProActive != null) {
        this.voilaCategory.products.push({
          id: null,
          name: eLifeProActive.name,
          description: "",
          consumerName: "",
          consumerUrl: "",
          ecpLink: "All package",
          warningText: "",
          icon: null,
          image: null,
          categoryId: this.voilaCategory.id,
          categoryName: this.voilaCategory.name,
          subCategoryId: eLifeProActive.id,
          subCategoryName: eLifeProActive.name,
          canNotBeDeleted: false,
          surveyId: this.$store.state.survey.id,
          isRelaxsee: false,
          isDisabled: false,
        });
      }
    },

    removeVoilaProduct(product, index) {
      var eLifeProActive = this.eLifeProSubCategoryMap.eLifeProActive;
      var deletingIndexsLevel3 = []; // [0,5,3];
      if (product.subCategoryName == null) {
          if (this.voilaCategory.products[index].id) {
              this.deletedProductIds = this.deletedProductIds.concat(
                  this.voilaCategory.products[index].id
              );
          }
          this.voilaCategory.products.splice(index, 1);
          this.hasSubVoilaCategoryProducts = false;
          this.hasSubAddElifeProReadProduct = true;
      } else if (product.subCategoryName != null) {
          for (let index = 0; index < this.voilaCategory.productsLevel3.length; index++) {
              var productLevel3 = this.voilaCategory.productsLevel3[index];
              if (productLevel3.subCategoryId != null && productLevel3.subCategoryId != eLifeProActive.id) {
                  if (productLevel3.id) {
                      this.deletedProductIds = this.deletedProductIds.concat(
                          productLevel3.id
                      );
                  }
                  deletingIndexsLevel3.push(index);
              }
          }
          this.voilaCategory.productsLevel3 = this.voilaCategory.productsLevel3.filter(
              function (value, index) {
                  return deletingIndexsLevel3.indexOf(index) == -1;
              }
          );
          this.hasSubVoilaCategoryProducts = false;
          this.hasSubAddElifeProReadProduct = false;
      }
      // else {
      //   if (this.voilaCategory.products[index].id) {
      //     this.deletedProductIds = this.deletedProductIds.concat(
      //       this.voilaCategory.products[index].id
      //     );
      //   }
      //   this.voilaCategory.products.splice(index, 1);
      //   this.hasSubVoilaCategoryProducts = false;
      // }
      // if  (this.voilaCategory.childCategories.length > 0) {
      //     this.hasSubVoilaCategoryProducts = true;
      // }
    },

    removeSeriesProduct(product, index) {
      var deletingIndexsLevel3 = [];
      if (product.subCategoryName == null) {
          if (this.seriesCategory.products[index].id) {
            this.deletedProductIds = this.deletedProductIds.concat(
              this.seriesCategory.products[index].id
            );
          }
          this.seriesCategory.products.splice(index, 1);
          this.hasSubSeriesCategoryProducts = false;
          this.hasSubCheckButtonAddMoreSoltesWideProduct = true;
      } else if (product.subCategoryName != null) {
          for (let index = 0; index < this.seriesCategory.productsLevel3.length; index++) {
              var productElife = this.seriesCategory.productsLevel3[index];
              if (productElife.subCategoryId != null) {
                  if (productElife.id) {
                      this.deletedProductIds = this.deletedProductIds.concat(
                          productElife.id
                      );
                  }
                  deletingIndexsLevel3.push(index);
              }
          }
          this.seriesCategory.productsLevel3 = this.seriesCategory.productsLevel3.filter(
              function (value, index) {
                  return deletingIndexsLevel3.indexOf(index) == -1;
              }
          );
          this.hasSubSeriesCategoryProducts = false;
          this.hasSubCheckButtonAddMoreSoltesWideProduct = false;
      }
      // else {
      //   if (this.seriesCategory.products[index].id) {
      //     this.deletedProductIds = this.deletedProductIds.concat(
      //       this.seriesCategory.products[index].id
      //     );
      //   }
      //   this.seriesCategory.products.splice(index, 1);
      //   this.hasSubSeriesCategoryProducts = false;
      // }
      // if  (this.seriesCategory.childCategories.length > 0) {
      //     this.hasSubSeriesCategoryProducts = true;
      // }
    },

    addCoatingProduct() {
      this.coatingCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.coatingCategory.id,
        categoryName: this.coatingCategory.name,
        subCategoryId: null,
        subCategoryName: null,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
    },

    removeCoatingProduct(index) {
      if (this.coatingCategory.products[index].id) {
        this.deletedProductIds = this.deletedProductIds.concat(
          this.coatingCategory.products[index].id
        );
      }
      this.coatingCategory.products.splice(index, 1);
      this.hasSubCoatingCategoryProducts = false;
    },

    addTintProduct() {
      this.tintCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.tintCategory.id,
        categoryName: this.tintCategory.name,
        subCategoryId: null,
        subCategoryName: null,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
    },

    addTintPolashadeProduct() {
      //  if (!this.hasTintPolashadeProduct){
      this.tintCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.tintCategory.id,
        categoryName: this.tintCategory.name,
        subCategoryId: this.subTintPolashadeId,
        subCategoryName: this.subTintPolashadeName,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
      //     this.hasTintPolashadeProduct = true
      //   }
    },

    addTintTransitionseProduct() {
      //   if (!this.hasTintTransitionsProduct){
      this.tintCategory.products.push({
        id: null,
        name: "",
        description: "",
        consumerName: "",
        consumerUrl: "",
        ecpLink: "All package",
        warningText: "",
        icon: null,
        image: null,
        categoryId: this.tintCategory.id,
        categoryName: this.tintCategory.name,
        subCategoryId: this.subTintTransitionsId,
        subCategoryName: this.subTintTransitionsName,
        canNotBeDeleted: false,
        surveyId: this.$store.state.survey.id,
        isRelaxsee: false,
        isDisabled: false,
      });
      //     this.hasTintTransitionsProduct = true
      //   }
    },

    removeTintProduct(product, index) {
      if (this.tintCategory.products[index].id) {
        this.deletedProductIds = this.deletedProductIds.concat(
          this.tintCategory.products[index].id
        );
      }
      this.tintCategory.products.splice(index, 1);
      if (product.subCategoryName === "Polashade") {
        this.hasTintPolashadeProduct = false;
      }
      if (product.subCategoryName === "Transitions") {
        this.hasTintTransitionsProduct = false;
      }
    },
  },
};
</script>

<style scoped>
.bold-border {
  border-top: 2px solid rgb(119, 119, 119);
}

.btn-tooltip {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.tooltip-img {
  cursor: pointer;
}
.custom-dialog {
  width: 467px;
  height: 283px;
  background: #4b4b4b;
}

.body-text-modal {
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.body-text-modal-elife-pro-read {
  width: 327px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 25px 0px 35px 0px;
  margin-bottom: 91px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  display: flex;
  justify-content: center;
}

.nav-link {
  font-size: 20px;
}

.padding-right {
  padding-right: 5%;
}

.product-database {
  font-size: 15px;
  line-height: 23px;
  font-family: "Montserrat", sans-serif;
}

.title-description {
  margin: 40px 0 28px 0;
}

.content {
  padding: 24px;
}

.custom-table {
  margin-top: 1.2rem;
}

td {
  word-break: break-word;
}

thead > tr > th {
  font-weight: bold !important;
  font-size: 11px;
  line-height: 14px;
  padding: 1rem 0.3rem;
  background: #f6f6f6;
}

table > thead {
  position: sticky !important;
  top: 0;
  z-index: 1000;
}

.category-name {
  color: #006db6;
  font-weight: bold;
}

.category-img {
  /* padding: 10px 0px 10px 10px;
  width: 30px;
  height: 40px; */
  border: .5px solid #848484;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}


.icon-img {
  margin: 0 auto;
}

.icon-img > img {
  border: .5px solid #848484;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

/* .icon-img > b-icon {
  margin-left: 10px;
} */

#falseinput img {
  width: 20px;
  height: 20px;
}

a {
  color: #0065fc;
}

.editor {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
}

.editor textarea {
  border: none!important;
  margin-bottom: 20px;
}

.editor textarea:focus, .editor textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.editor span {
  color: #768192;
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 10px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: black;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 9px;
  content: " ";
  display: block;
  background: black;
}
</style>