<template>
  <b-popover :target="`popover-category-${productType}-${currentRowIndex}`" triggers="hover" placement="righttop">
    <template #title>
      <span style="color:blue">
        {{ currentName }}
      </span>
    </template>
    <img
      :src="currentImage"
      width="200px"
    >
  </b-popover>
</template>

<script>
export default {
  props: {
    productType: {
      type: String,
      default: ""
    },
    currentRowIndex: {
      type: Number,
      default: 0
    },
    currentName: {
      type: String,
      default: ""
    },
    currentImage: {
      type: String,
      default: ""
    }
  }
}
</script>